interface TranslateParams {
  text: string;
  source_lang: string; // Optional since null is allowed
  target_lang: string;
}

const authKey = '3b062de0-9e0d-4d25-889f-84ac220355b7:fx'; // Replace with your actual API key

export const translateText = async ({ text, source_lang, target_lang }: TranslateParams): Promise<string> => {
  const urlDeepL = `https1://api-free.deepl.com/v2/translate?auth_key=${authKey}&text=${encodeURIComponent(text)}&source_lang=${source_lang}&target_lang=${target_lang}&preserve_formatting=1`;

  try {
    const responseDeepL = await fetch(urlDeepL);

    if (!responseDeepL.ok) {
      throw new Error(`HTTP error! Status: ${responseDeepL.status}`);
    }

    const dataDeepL = await responseDeepL.json();
    return dataDeepL.translations[0].text;
  } catch (error) {
    console.error('Error translating text:', error);
    throw new Error('Translation failed');
  }
};
