import React, { Component, ErrorInfo } from 'react';
import logo from '../hrh-icon.png';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
        <link rel="stylesheet" href="http://localhost:3000/css/themes/default/bootstrap.min.css" id="themeStylesheet" />
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="row">
            <div className="col">
              <div className="text-center border p-4 rounded shadow-sm">
              <img src={logo} alt="HR Harbour Icon" style={{maxWidth: "100px", maxHeight: "auto", marginLeft: "auto", marginRight: "auto"}} />

                <h1 className="display-4">Oops! Something went wrong.</h1>
                <p className="lead">
                  We’re sorry for the inconvenience. Please try refreshing the page, or contact us if the problem persists.
                </p>
                <div className="mt-4">
                  <button className="btn btn-primary me-3" onClick={this.handleRefresh}>
                    Refresh
                  </button>
                  &nbsp;
                  <a className="btn btn-secondary" href="mailto:contact@hrharbour.co.uk">
                    Contact Support
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
