import React, { useEffect, useState } from 'react';
import { useMessageData } from '../hooks/useMessageData';
import Loading from '../../../components/Common/Loading';
import Error from '../../../components/Common/Error';
import { useTranslation } from 'react-i18next';
import MessageThread from './MessageThread';
import { Thread, User } from '../types/types';
import styles from './Messages.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEnvelopeOpen, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

import './Messages.module.css';

const MessageList: React.FC = () => {
  const { t } = useTranslation('messages');
  const { threads, status, error } = useMessageData();
  const { threadId } = useParams<{ threadId: string }>(); // Extract the threadId from the URL
  const [selectedThread, setSelectedThread] = useState<string | null>(null);

  const mockUser: User = { id: '1', name: 'mock', isOnline: true };

  useEffect(() => {
    if (threadId) {
      setSelectedThread(threadId);
    } else {
      setSelectedThread(null);
    }
  }, [threadId]);

  const handleThreadClick = (threadId: string) => {
    setSelectedThread(threadId);
  };

  if (status === 'failed') {
    return <Error error={error || t('failed-to-load-messages')} />;
  }

  if (status === 'succeeded' && threads.length === 0) {
    return <div>{t('no-messages')}</div>;
  }

  const selectedThreadObj: Thread | undefined = threads.find(thread => thread.id === selectedThread);

  return (
    <Loading loading={(status === 'loading' || status === 'idle')}>

    <div className={`${styles.containerFluid} ${styles.chatApp}`}>
      <div className={`${styles.heightFix} row`}>
        <div className="col-lg-12">
          <div className={styles.card}>
            <div className={styles.peopleList}>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>
                <input type="text" className="form-control" placeholder={t('search')} />
              </div>
              <ul className={`${styles.chatList} list-unstyled mt-2 mb-0`}>
                {threads.map((thread) => {
                  const lastMessage = thread.messages[thread.messages.length - 1];
                  const isUnread = !lastMessage?.read && lastMessage.sender.id !== mockUser.id;
                  return (
                    <li
                      key={thread.id}
                      className={`${styles.clearfix} ${selectedThread === thread.id ? styles.active : ''}`}
                      onClick={() => handleThreadClick(thread.id)} // Use the new handler
                    >
                      <div className={styles.subjectImageContainer}>
                        <div className={styles.subjectImage}>{thread.subject[0]}</div>
                        <FontAwesomeIcon icon={isUnread ? faEnvelope : faEnvelopeOpen} className={`${styles.readStatusIcon}`} />
                      </div>
                      <div className={styles.about}>
                        <div className={`${styles.name} ${isUnread ? styles.unread : ''}`}>{thread.subject}</div>
                        <div className={styles.lastMessagePreview}>{lastMessage?.content}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div style={{width: "100%"}}>
              {selectedThreadObj ? (
                <MessageThread thread={selectedThreadObj} currentUser={mockUser} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </Loading>
  );
};

export default MessageList;
