import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "../types/types";
import { NavLink } from "react-router-dom";

type CollapsibleMenuItemProps = {
  menu: MenuItem;
  isOpen: boolean;
  onToggle: () => void;
};

const CollapsibleMenuItem = ({ menu, isOpen, onToggle }: CollapsibleMenuItemProps) => {
    return (
      <>
        <a
          className="nav-link collapsed"
          href="#"
          onClick={onToggle}
          aria-expanded={isOpen}
          aria-controls={menu.label}
        >
          <FontAwesomeIcon icon={menu.icon} className="fas fa-fw" />
          <span>&nbsp;{menu.label}</span>
        </a>
        <div
          id={menu.label}
          className={`collapse ${isOpen ? "show" : ""}`}
          aria-labelledby={menu.label}
          data-parent="#accordionSidebar"
        >
          <div className="bg-white-actual py-2 collapse-inner rounded">
            <h6 className="collapse-header">{menu.label} menu</h6>
            {menu.submenu?.map((subMenu) => (
              <NavLink key={subMenu.title} className="collapse-item" to={subMenu.navPath || subMenu.route}onClick={onToggle}>
                {subMenu.title}
              </NavLink>
            ))}
          </div>
        </div>
      </>
    );
  };
  
  export default CollapsibleMenuItem;