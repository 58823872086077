import React, { useState, useEffect } from 'react';
import { useDashboard } from '../hooks/useDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckCircle, faCalendarCheck, faDownload, faComments, faCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation('dashboard');

  const { annualLeaveStats, requestsAndTasks, daysSinceLastHoliday } = useDashboard();

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = () => {
    setOpenDropdown(null);
  };

  return (
<>
  {/* Page Heading */}
  <div className="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 className="h3 mb-0 text-gray-800">{t('dashboard-holidays-h1')}</h1>
    <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
      <FontAwesomeIcon icon={faDownload} className="text-white-50" /> {t('dashboard-generate-report')}
    </a>
  </div>

  {/* Content Row */}
  <div className="row">
    {/* Annual Leave Awaiting Approval */}
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{t('annual-leave-awaiting-approval')}</div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">{annualLeaveStats?.awaitingApproval || '0.00'}</div>
            </div>
            <div className="col-auto">
              <FontAwesomeIcon icon={faClock} className="fa-2x text-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Annual Leave Approved */}
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-success shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">{t('annual-leave-approved')}</div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">{annualLeaveStats?.approved || '0.00'}</div>
            </div>
            <div className="col-auto">
              <FontAwesomeIcon icon={faCheckCircle} className="fa-2x text-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Annual Leave Taken */}
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-info shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">{t('annual-leave-taken')}</div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">{annualLeaveStats?.taken || '0.00'}</div>
            </div>
            <div className="col-auto">
              <FontAwesomeIcon icon={faCalendarCheck} className="fa-2x text-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Pending Requests */}
    <div className="col-xl-3 col-md-6 mb-4">
      <div className="card border-left-warning shadow h-100 py-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">{t('pending-requests')} (18)</div>
              <div className="row no-gutters align-items-center">
                <div className="col-auto">
                  <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                </div>
                <div className="col">
                  <div className="progress progress-sm mr-2">
                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <FontAwesomeIcon icon={faComments} className="fa-2x text-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Content Row */}
  <div className="row">
    {/* Area Chart */}
    <div className="col-xl-8 col-lg-7">
      <div className="card shadow mb-4" style={{overflow: "auto"}}>
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">{t('requests-and-tasks')}</h6>
          <div className="dropdown no-arrow">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink1"
              onClick={() => toggleDropdown('dropdown1')}
              aria-haspopup="true"
              aria-expanded={openDropdown === 'dropdown1' ? 'true' : 'false'}
            >
              <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
            </a>
            {openDropdown === 'dropdown1' && (
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                aria-labelledby="dropdownMenuLink1"
                onClick={handleMenuItemClick}
              >
                <div className="dropdown-header">{t('dropdown-header')}</div>
                <a className="dropdown-item" href="#">
                  {t('action')}
                </a>
                <a className="dropdown-item" href="#">
                  {t('another-action')}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  {t('something-else-here')}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-area">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>ID</th>
                  <th>{t('type')}</th>
                  <th>{t('description')}</th>
                  <th>{t('due-date')}</th>
                  <th>{t('issued-by')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {requestsAndTasks.map((task) => (
                  <tr key={task.id}>
                    <td>{task.friendlyId}</td>
                    <td>{task.type}</td>
                    <td>{task.description}</td>
                    <td>{task.dueDate}</td>
                    <td>{task.issuedBy}</td>
                    <td>
                      <button className="btn btn-primary btn-sm">{t('view')}</button> &nbsp;
                      <button className="btn btn-success btn-sm">{t('complete')}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    {/* Pie Chart */}
    <div className="col-xl-4 col-lg-5">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 className="m-0 font-weight-bold text-primary">{t('days-since-last-holiday')}</h6>
          <div className="dropdown no-arrow">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink2"
              onClick={() => toggleDropdown('dropdown2')}
              aria-haspopup="true"
              aria-expanded={openDropdown === 'dropdown2' ? 'true' : 'false'}
            >
              <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
            </a>
            {openDropdown === 'dropdown2' && (
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                aria-labelledby="dropdownMenuLink2"
                onClick={handleMenuItemClick}
              >
                <div className="dropdown-header">{t('dropdown-header')}</div>
                <a className="dropdown-item" href="#">
                  {t('action')}
                </a>
                <a className="dropdown-item" href="#">
                  {t('another-action')}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  {t('something-else-here')}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="chart-pie pt-4 pb-2">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <span className="fill-text-10">{daysSinceLastHoliday[0]?.days}</span>
            </div>
          </div>
          <div className="mt-4 text-center small">
            <span className="mr-2"></span>
            <span className="mr-2"></span>
            <span className="mr-2"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Content Row */}
  <div className="row">
    <div className="col-lg-6 mb-4">
      {/* Project Card Example */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">{t('projects')}</h6>
        </div>
        <div className="card-body">
          <h4 className="small font-weight-bold">{t('server-migration')} <span className="float-right">20%</span></h4>
          <div className="progress mb-4">
            <div className="progress-bar bg-danger" role="progressbar" style={{ width: '20%' }}></div>
          </div>
          <h4 className="small font-weight-bold">{t('sales-tracking')} <span className="float-right">40%</span></h4>
          <div className="progress mb-4">
            <div className="progress-bar bg-warning" role="progressbar" style={{ width: '40%' }}></div>
          </div>
          <h4 className="small font-weight-bold">{t('customer-database')} <span className="float-right">60%</span></h4>
          <div className="progress mb-4">
            <div className="progress-bar" role="progressbar" style={{ width: '60%' }}></div>
          </div>
          <h4 className="small font-weight-bold">{t('payout-details')} <span className="float-right">80%</span></h4>
          <div className="progress mb-4">
            <div className="progress-bar bg-info" role="progressbar" style={{ width: '80%' }}></div>
          </div>
          <h4 className="small font-weight-bold">{t('account-setup')} <span className="float-right">{t('complete')}</span></h4>
          <div className="progress">
            <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>

      {/* Color System */}
      <div className="row">
        <div className="col-lg-6 mb-4">
          <div className="card bg-primary text-white shadow">
            <div className="card-body">
              {t('primary')}
              <div className="text-white-50 small">#4e73df</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-success text-white shadow">
            <div className="card-body">
              {t('success')}
              <div className="text-white-50 small">#1cc88a</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-info text-white shadow">
            <div className="card-body">
              {t('info')}
              <div className="text-white-50 small">#36b9cc</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-warning text-white shadow">
            <div className="card-body">
              {t('warning')}
              <div className="text-white-50 small">#f6c23e</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-danger text-white shadow">
            <div className="card-body">
              {t('danger')}
              <div className="text-white-50 small">#e74a3b</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-secondary text-white shadow">
            <div className="card-body">
              {t('secondary')}
              <div className="text-white-50 small">#858796</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-light text-black shadow">
            <div className="card-body">
              {t('light')}
              <div className="text-black-50 small">#f8f9fc</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card bg-dark text-white shadow">
            <div className="card-body">
              {t('dark')}
              <div className="text-white-50 small">#5a5c69</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-6 mb-4">
  <div className="card shadow mb-4">
    <div className="card-header py-3">
      <h6 className="m-0 font-weight-bold text-primary">{t('Noticeboard')}</h6>
    </div>
    <div className="card-body">
      
      <div className="mb-4">
        <h6 className="font-weight-bold text-secondary">Annual Company Picnic</h6>
        <p>Join us for our annual company picnic at Greenfield Park on September 15th! There will be food, games, and activities for all ages. RSVP by September 1st.</p>
      </div>

      <div className="mb-4">
        <h6 className="font-weight-bold text-secondary">New Wellness Program</h6>
        <p>We're excited to launch our new Wellness Program starting October 1st. Weekly yoga classes, health screenings, and wellness challenges will be available for all employees.</p>
        <a href="/wellness-program" className="text-primary">Learn More</a>
      </div>

      <div className="mb-4">
        <h6 className="font-weight-bold text-secondary">Office Renovation</h6>
        <p>Our HQ office is getting a makeover! Renovations will begin on September 20th and are expected to last until December. Please plan accordingly as some areas may be temporarily inaccessible.</p>
      </div>

      <div className="mb-4">
        <h6 className="font-weight-bold text-secondary">TechWave Innovators Conference</h6>
        <p>Mark your calendars for the TechWave Innovators Conference on November 5th. This year’s theme is "Shaping the Future of Technology." Register by October 20th to secure your spot.</p>
        <a href="/conference-2024" className="text-primary">Register Now</a>
      </div>

    </div>
  </div>
</div>

  </div>
</>

  );
};

export default Dashboard;
