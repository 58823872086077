import { combineReducers } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../slices/authSlice';
import navbarReducer from '../slices/navbarSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  counter: counterReducer,
  navbar: navbarReducer
});

export default rootReducer;
