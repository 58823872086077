import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks'; //TODO this should be my features hooks
import { fetchAlertsThunk, selectAllAlerts, selectAlertStatus, selectAlertError } from '../slices/alertsSlice';

export const useAlertData = () => {
  
  const dispatch = useAppDispatch();

  const alerts = useAppSelector(selectAllAlerts);
  const status = useAppSelector(selectAlertStatus);
  const error = useAppSelector(selectAlertError);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAlertsThunk());
    }
  }, [dispatch, status]);

  return { alerts, status, error };
};
