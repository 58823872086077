import React from 'react';
import './Modal.module.css';

interface ModalProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  modalWidth?: string;
  modalHeight?: string;
}

const Modal: React.FC<ModalProps> = ({ title, onClose, children, modalWidth = '600px', modalHeight = '620px' }) => {
  return (
    <div className="modal show d-block" tabIndex={-1} role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: modalWidth, minWidth: modalWidth }}>
        <div className="modal-content" style={{ maxHeight: modalHeight, minHeight: modalHeight  }}>
          <div className="modal-header">
            <h2>{title}</h2>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
