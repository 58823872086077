import { UserProfile } from '../types/types';

export const fetchUserProfile = async (): Promise<UserProfile> => {
  const mockUserProfile: UserProfile = {
    id: 'user123',
    fullName: 'Nora Doe',
    preferredName: 'Nora',
    professionalSuffix: 'PhD',
    department: 'Engineering',
    jobTitle: 'Lead Engineer',
    hireDate: '2021-01-15',
    manager: 'John Smith',
    profilePicture: 'https://example.com/profile.jpg',
    location: 'New York',
    description: 'Lead engineer focusing on network security and infrastructure.',
    contactDetails: {
      phone: '123-456-7890',
      email: 'nora.doe@example.com',
    },
    socialLinks: [
      { platform: 'LinkedIn', url: 'https://linkedin.com/in/noradoe' },
      { platform: 'GitHub', url: 'https://github.com/noradoe' },
    ],
    focusAreas: [
      'Product Infrastructure',
      'Network Security',
      'Security Testing',
      'Security Audit Outsourcing',
      'Bugs',
    ],
    collaborators: [
      { name: 'Joe A.', avatarUrl: 'https://example.com/avatars/joe.png' },
      { name: 'Dylan C.', avatarUrl: 'https://example.com/avatars/dylan.png' },
    ],
    teams: ['Team Alpha', 'Team Beta'],
  };

  return mockUserProfile;
};
