import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { fetchUserProfile, updateUserProfile, selectCurrentUserProfile, setCurrentUserId, setStatusLoading, setStatusSucceeded } from '../slices/userDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { UserProfile } from '../types/types';

export const useUserData = (userId: string | null) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUserProfile = useSelector((state: RootState) => selectCurrentUserProfile(state));
  const status = useSelector((state: RootState) => state.user.status);
  const error = useSelector((state: RootState) => state.user.error);
  const user = useSelector((state: RootState) => state.rootReducer.auth?.user);

  const userAccessToken = user?.access_token ?? '';

  const profiles = useSelector((state: RootState) => state.user.profiles);

  const [newProfile, setNewProfile] = useState<UserProfile | null>(currentUserProfile);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.expired)
    {
    if (userId !== null) {
      const currentProfile = profiles[userId];
      if (status === 'idle' || !currentProfile) {
        dispatch(fetchUserProfile({ userId, token: userAccessToken }));
      }
   
      dispatch(setCurrentUserId(userId));
    }
  }
  }, [status, dispatch, userId, userAccessToken, profiles, currentUserProfile]);

  useEffect(() => {
      setNewProfile(currentUserProfile);
  }, [currentUserProfile, dispatch]);



  return {
    userProfile: newProfile,
    status,
    error,
    loading: status === 'loading' || saving
  };
};
