import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-http-backend';
import {translateText} from '../services/translationService'
import LocalStorageBackend from "i18next-localstorage-backend";
import FsBackend from "i18next-fs-backend";

//TODOs
//1) Get translations from API (as in the stored files)
//2) Allow updates to go to the API (missing keys update the persisted translations)
//3) Double check refetches, it seems to report missing even though we've added it 
//dynamically so perhaps an additional check to make sure we've not already translated on the fly
//4) Use chapt gpt to extend the locals and langauge pieces (look at string interop as well as this might help with deimcal places etc)


i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en-GB',
    //fallbackLng: 'en-GB', //Removed as we set the fall back in the missingKeyHandler.
    load: 'all', // Load only the current language
    debug: false,
    backend: {
      LocalStorageBackend,
      backendOptions: [{
        expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        loadPath: './locales_cache/{{lng}}/{{ns}}.json',
        addPath: './locales_cache/{{lng}}/{{ns}}.json'
      },
      {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      }] // Path to your translation files
    },
    missingKeyNoValueFallbackToKey: false,
    saveMissing: true,

    missingKeyHandler: async function(lngs, ns, key, fallbackValue, updateMissing, options) {
      const targetLanguage = i18n.language;
      
      // Try to lookup the translation from the service
      const fallbackLanguage = 'en-GB'; // Your fallback language
      const fallBackValue = i18n.getResource(fallbackLanguage, ns, key);
        // Check fallback language for the translation
      const translatedFallBack = await translateText({
        text: fallBackValue,
        source_lang: 'en',  //(is just EN due to DeepL)
        target_lang: targetLanguage
      }).catch(()=> {});
        if (translatedFallBack) {
          i18n.addResource(targetLanguage, ns, key, translatedFallBack);
        } else {
          // Final fallback value if all else fails
          i18n.addResource(targetLanguage, ns, key, fallBackValue);
        }
    }
  });

export default i18n;
