import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { MenuItem } from "../types/types";
import CollapsibleMenuItem from "./CollapsibleMenuItem";

type MenuItemComponentProps = {
  menu: MenuItem;
  isOpen: boolean;
  onToggle: () => void;
};

const MenuItemComponent = ({ menu, isOpen, onToggle }: MenuItemComponentProps) => {

  return (
    <li className="nav-item">
      {menu.submenu ? (
        <CollapsibleMenuItem menu={menu} isOpen={isOpen} onToggle={onToggle} />
      ) : (
        <NavLink className="nav-link" to={menu.navPath || menu.route}>
          <FontAwesomeIcon icon={menu.icon} />
          <span>&nbsp;{menu.title}</span>
        </NavLink>
      )}
    </li>
  );
};

export default MenuItemComponent;