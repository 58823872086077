import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { selectCurrentEmployeeProfile } from '../slices/employeeDetailsSlice'; // Import the updated selector
import styles from './EmployeeProfile.module.css';

interface ContactInfoProps {
  onEdit: () => void;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ onEdit }) => {
  const { t } = useTranslation('employee');
  const contactInfo = useSelector((state: RootState) => {
    const employeeProfile = selectCurrentEmployeeProfile(state);
    return employeeProfile ? employeeProfile.contactDetails : null;
  });

  return (
    <div>
      <div>
        <p><strong>{t('contact_info.phone')}:</strong> </p><p> {contactInfo?.phone}</p>
        <p><strong>{t('contact_info.email')}:</strong> </p><p> {contactInfo?.email}</p>
        <p><button hidden className="btn btn-primary" onClick={onEdit}>{t('contact_info.edit')}</button></p>
      </div>
    </div>
  );
};

export default ContactInfo;
