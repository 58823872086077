import React from 'react';
import { useSelector } from 'react-redux';
import { calculateAbsenceStatistics } from './calculateAbsenceStatistics';
import { RootState } from '../../../store/store';

interface AbsenceStatisticsProps {
  employeeId?: string;
  teamView?: boolean;
  totalAllowance: number;
}

const AbsenceStatistics: React.FC<AbsenceStatisticsProps> = ({ employeeId, teamView = false, totalAllowance }) => {

  const absences = useSelector((state: RootState) => state.absences.absences);

  const employeeAbsences = employeeId
    ? absences.filter(abs => abs.employeeId === employeeId)
    : absences;

  const { groupedAbsences, remainingLeave } = calculateAbsenceStatistics(employeeAbsences, totalAllowance);

  const title = teamView ? `${employeeId ? employeeId : 'Team'} Statistics` : 'Your Statistics';

  return (
    <div className="absence-statistics">
      <h4>{title}</h4>
      {Object.entries(groupedAbsences).map(([type, daysTaken]) => (
        <p key={type}>{type.charAt(0).toUpperCase() + type.slice(1)} taken: {daysTaken} days</p>
      ))}
      {!teamView && <p>Remaining entitlement leave: {remainingLeave} days</p>}
    </div>
  );
};

export default AbsenceStatistics;
