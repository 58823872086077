import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer style={{ padding: '1rem', borderTop: '1px solid #ccc', backgroundColor: '#f5f5f5' }}>
      <p>Footer content here</p>
    </footer>
  );
};

export default Footer;
