import React, { useState } from 'react';
import { AppDispatch } from '../../../../store/store';
import { updateUserProfile } from '../../slices/userDetailsSlice';
import { useDispatch } from 'react-redux';
import { UserProfile, Collaborator } from '../../types/types';
import SaveButtonWithDropdown from '../../../../components/Common/SaveButtonWithDropdown';

interface EditOverviewViewProps {
  profile: UserProfile;
  onClose: () => void;
}

const EditOverviewView: React.FC<EditOverviewViewProps> = ({ profile, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const [newProfile, setNewProfile] = useState<UserProfile>(profile);
  const [continueEditing, setContinueEditing] = useState(false);

  const handleFocusAreasChange = (index: number, value: string) => {
    const newFocusAreas = [...newProfile.focusAreas];
    newFocusAreas[index] = value;
    setNewProfile({ ...newProfile, focusAreas: newFocusAreas });
  };

  const handleAddFocusArea = () => {
    setNewProfile({ ...newProfile, focusAreas: [...newProfile.focusAreas, ''] });
  };

  const handleCollaboratorChange = (index: number, field: keyof Collaborator, value: string) => {
    const newCollaborators = [...newProfile.collaborators];
    newCollaborators[index] = { ...newCollaborators[index], [field]: value };
    setNewProfile({ ...newProfile, collaborators: newCollaborators });
  };

  const handleAddCollaborator = () => {
    setNewProfile({
      ...newProfile,
      collaborators: [...newProfile.collaborators, { name: '', avatarUrl: '' }],
    });
  };

  const handleTeamsChange = (index: number, value: string) => {
    const newTeams = [...newProfile.teams];
    newTeams[index] = value;
    setNewProfile({ ...newProfile, teams: newTeams });
  };

  const handleAddTeam = () => {
    setNewProfile({ ...newProfile, teams: [...newProfile.teams, ''] });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewProfile({ ...newProfile, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent, continueEditing = false, setDropdownOpenCallback?: (open: boolean) => void) => {
    e.preventDefault();
    await dispatch(updateUserProfile(newProfile));
    if (!continueEditing) {
      onClose();
    }
    if (setDropdownOpenCallback) {
      setDropdownOpenCallback(false);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, continueEditing)}>
      <div className="row">
        <div className="col-md-6">
          <fieldset>
            <legend>Edit Focus Areas</legend>
            {newProfile.focusAreas.map((area, index) => (
              <div key={index} className="form-group">
                <label>Focus Area {index + 1}</label>
                <input
                  type="text"
                  className="form-control"
                  value={area}
                  onChange={(e) => handleFocusAreasChange(index, e.target.value)}
                />
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={handleAddFocusArea}>
              Add Focus Area
            </button>
          </fieldset>

          <fieldset>
            <legend>Edit Teams</legend>
            {newProfile.teams.map((team, index) => (
              <div key={index} className="form-group">
                <label>Team {index + 1}</label>
                <input
                  type="text"
                  className="form-control"
                  value={team}
                  onChange={(e) => handleTeamsChange(index, e.target.value)}
                />
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={handleAddTeam}>
              Add Team
            </button>
          </fieldset>
        </div>

        <div className="col-md-6">
          <fieldset>
            <legend>Edit Collaborators</legend>
            {newProfile.collaborators.map((collaborator, index) => (
              <div key={index} className="form-group">
                <label>Collaborator {index + 1}</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Name"
                  value={collaborator.name}
                  onChange={(e) => handleCollaboratorChange(index, 'name', e.target.value)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Avatar URL"
                  value={collaborator.avatarUrl}
                  onChange={(e) => handleCollaboratorChange(index, 'avatarUrl', e.target.value)}
                />
              </div>
            ))}
            <button type="button" className="btn btn-secondary" onClick={handleAddCollaborator}>
              Add Collaborator
            </button>
          </fieldset>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <SaveButtonWithDropdown
          onSave={(e: React.FormEvent<Element>) => handleSubmit(e, false)}
          loading={false} // Replace this with the appropriate loading state if available
          saveLabel="Save"
          saveAndContinueLabel="Save and Continue"
        />
      </div>
    </form>
  );
};

export default EditOverviewView;
