import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditForm from '../../../components/Common/EditForm';
import FormInput from '../../../components/Common/FormInput';
import SaveButtonWithDropdown from '../../../components/Common/SaveButtonWithDropdown';
import { selectPersonalDetails, updateEmployeeProfile } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { EmployeeProfile } from '../types/types';

const EditPersonalDetails: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

  // Retrieve employee profile from Redux state
  const employeeProfile = useSelector((state: RootState) => selectPersonalDetails(state));
  const loading = useSelector((state: RootState) => state.employee.loading);
  const error = useSelector((state: RootState) => state.employee.error);

  // Local state for managing form data
  const [formData, setFormData] = useState<EmployeeProfile | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // Sync local state with Redux state when employeeProfile changes
  useEffect(() => {
    setFormData(employeeProfile);
  }, [employeeProfile]);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (formData) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent, continueEditing = false, setDropdownOpenCallback?: (open: boolean) => void) => {
    e.preventDefault();
    if (formData) {
      dispatch(updateEmployeeProfile(formData));
    }
    setDropdownOpen(false);
    if (!continueEditing) {
      onClose();
    }
    if (setDropdownOpenCallback) {
      setDropdownOpenCallback(false);
    }
  };

  if (!formData) {
    // Optionally render a loading indicator or a message if the data is not yet available
    return <div>Loading...</div>;
  }

  return (
    <EditForm loading={loading} error={error} onSubmit={handleSubmit}>
      <div className={`card mb-3`}>
        <div className="card-body" style={{ paddingTop: '20px' }}>
          <div className="row">
            <div className="col-md-6">
              <FormInput label={t('full_name')} type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
              <FormInput label={t('preferred_name')} type="text" name="preferredName" value={formData.preferredName} onChange={handleChange} />
              <FormInput label={t('professional_suffix')} type="text" name="professionalSuffix" value={formData.professionalSuffix} onChange={handleChange} />
              <FormInput label={t('payroll_name')} type="text" name="payrollName" value={formData.payrollName} onChange={handleChange} />
              <FormInput label={t('tax_id')} type="text" name="taxID" value={formData.taxID} onChange={handleChange} />
              <FormInput label={t('date_of_birth')} type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <FormInput label={t('hire_date')} type="date" name="hireDate" value={formData.hireDate} onChange={handleChange} />
              <FormInput label={t('status')} type="text" name="status" value={formData.status} onChange={handleChange} />
              <FormInput label={t('position')} type="text" name="jobTitle" value={formData.jobTitle} onChange={handleChange} />
              <FormInput label={t('department')} type="text" name="department" value={formData.department} onChange={handleChange} />
              <FormInput label={t('marital_status')} type="text" name="maritalStatus" value={formData.maritalStatus} onChange={handleChange} />
              <FormInput label={t('gender')} type="text" name="gender" value={formData.gender} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <SaveButtonWithDropdown
          onSave={handleSubmit}
          loading={loading}
          saveLabel={t('save')}
          saveAndContinueLabel={t('save_and_continue')}
        />
      </div>
    </EditForm>
  );
};

export default EditPersonalDetails;
