import React, { useEffect, useState } from "react";
import { TailSpin }  from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import styles from "./Callback.module.css"; // Import the CSS file for styling

import { AppDispatch } from '../store/store';
import { useDispatch } from 'react-redux';
import { handleLoginCallback } from '../slices/authSlice';

export const Callback: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(handleLoginCallback());
  }, [dispatch]);

  useEffect(() => {
    // Simulate a loading or login process
    const loginProcess = async () => {

      //Whilst the async auth processes are completing show a nice page for 1.75 seconds...
      await new Promise((resolve) => (setTimeout(resolve, 1750)));

      // Redirect to the root of the app after login
      navigate("/");
    };

    // Call the login process function
    loginProcess().finally(() => {
      // Set loading to false when the login process is complete
      setLoading(false);
    });
  }, [navigate]);

  return (
    <div className={styles.overlay}>
      {loading && (
        <div className={styles.container}>
<TailSpin   // Type of spinner
    height="80"
    width="80"
    color="#4fa94d"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
/>          
<div>Hold on, we're just logging you in...</div>
        </div>
      )}
    </div>
  );
};

export default Callback;
