import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeeProfile, selectCurrentEmployeeProfile } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { EmployeeProfile } from '../types/types';
import { useTranslation } from 'react-i18next';
import styles from './Edit.module.css';

const EditCustomFields: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();
  const profile = useSelector((state: RootState) => selectCurrentEmployeeProfile(state));
  const loading = useSelector((state: RootState) => state.employee.status === 'loading');
  const error = useSelector((state: RootState) => state.employee.error);

  const [newProfile, setNewProfile] = useState<EmployeeProfile | null>(profile);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setNewProfile(profile);
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (newProfile) {
      setNewProfile({ ...newProfile, [name]: value });
    }
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    if (newProfile) {
      dispatch(updateEmployeeProfile(newProfile));
      setDropdownOpen(false);  // Close the dropdown
      if (!continueEditing) {
        onClose();
      }
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      {loading && (
        <div className={styles.modalOverlay}>
          <p>{t('updating')}</p>
        </div>
      )}
      <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
        <div className={`card mb-3 ${styles.card}`}>
          <div className="card-body" style={{ paddingTop: '20px' }}>
            <div className="form-group">
              <label>{t('shoe_size')}:</label>
              <input
                type="text"
                className="form-control"
                name="shoeSize"
                value={newProfile?.shoeSize || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{t('t_shirt_size')}:</label>
              <input
                type="text"
                className="form-control"
                name="tShirtSize"
                value={newProfile?.tShirtSize || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{t('uniform_size')}:</label>
              <input
                type="text"
                className="form-control"
                name="uniformSize"
                value={newProfile?.uniformSize || ''}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
          <div className="btn-group">
            <button className="btn btn-success" type="submit" disabled={loading}>
              {t('save')}
            </button>
            <button
              className="btn btn-success dropdown-toggle dropdown-toggle-split"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            >
            </button>
            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
              <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
                {t('save_and_continue')}
              </button>
            </div>
          </div>
        </div>
        {error && <p className="text-danger">{error}</p>}
      </form>
    </>
  );
};

export default EditCustomFields;
