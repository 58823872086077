// src/api/absenceMockApi.ts
import { Absence } from '../types/types';

let mockAbsences: Absence[] = [
  {
    id: '1',
    employeeId: 'emp1',
    startDate: '2024-08-28',
    endDate: '2024-08-30',
    type: 'holiday',
    status: 'approved',
  },
  {
    id: '2',
    employeeId: 'emp2',
    startDate: '2024-09-01',
    endDate: '2024-09-02',
    type: 'sick',
    status: 'pending',
  },
];

const mockPublicHolidays = [
  { date: '2024-12-25', type: 'publicHoliday', description: 'Christmas Day' },
  { date: '2024-12-26', type: 'bankHoliday', description: 'Boxing Day' },
  // Include weekends or other types as necessary
];

const simulateNetworkDelay = (response: any) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, 500);
  });
};

export const fetchAbsencesApi = async (employeeId: string) => {
  return simulateNetworkDelay({ data: mockAbsences });
};

export const bookAbsenceApi = async (absence: Absence) => {

  // Create a new array with the new absence
  const newAbsences = [...mockAbsences, { ...absence, id: (mockAbsences.length + 1).toString() }];
 
  return simulateNetworkDelay({ data: { ...absence, id: (mockAbsences.length + 1).toString() } });
};

export const fetchNonWorkingDaysApi = async (employeeId: string) => {
  return { mockPublicHolidays };
};
