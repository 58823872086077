import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { TailSpin } from "react-loader-spinner";
import styles from "./AutoLogin.module.css"; // Import the CSS file for styling
import { login } from '../slices/authSlice';

import Cookies from 'universal-cookie';
import { User } from 'oidc-client';

interface AutoLoginProps {
  user: User | null;
}


const TypingText: React.FC = () => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  const words = "Welcome to HR Harbour :)";



  useEffect(() => {
    const interval = setInterval(() => {
      if (index < words.length) {
        setText(words.slice(0, index + 1));
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval); // Stop the interval once the entire text is displayed
      }
    }, 80);

    return () => clearInterval(interval);
  }, [index]);

  return <div className={styles.typingText}>{text}</div>;
};


export const AutoLogin: React.FC<AutoLoginProps> = (props) => {

  const cookies = new Cookies();

  const handleLogin = () => {
    dispatch(login());
  };

  const SetCookie = () => 
  {
    cookies.set('hasTriedSignin', 'true', { path: '/', maxAge: 15, secure: true, sameSite: 'strict' });
  };

  const dispatch = useDispatch<AppDispatch>();
  var hasTried = cookies.get('hasTriedSignin');
   // automatically sign-in

  useEffect(() => {
    if (props.user === null && !hasTried) {
      setTimeout(() => {
        SetCookie();
        handleLogin();
      }, 3000);
    }
    setTimeout(() => {
      if (props.user === null && !hasTried) {
        SetCookie();
        handleLogin();
      }
    }, 7250);
  }, [dispatch, SetCookie, handleLogin, hasTried, props.user]);

  return (
    (props.user === null && !hasTried) ?
      <div className={styles.overlay}>
        <div className={styles.container}>
          <TypingText />
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </div> : <></>
  );
};
