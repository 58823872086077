import React, { useState, useEffect } from 'react';
import { AppDispatch, RootState } from '../../../../store/store';
import { selectCurrentUserProfile, updateUserProfile } from '../../slices/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../types/types';

const EditEmployeeDetails: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const userProfile = useSelector((state: RootState) => selectCurrentUserProfile(state));
  const [continueEditing, setContinueEditing] = useState(false);
  const [newProfile, setNewProfile] = useState<UserProfile | null>(userProfile);

  useEffect(() => {
    if (userProfile) {
      setNewProfile(userProfile);
    }
  }, [userProfile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (newProfile) {
      setNewProfile({ ...newProfile, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newProfile) {
      await dispatch(updateUserProfile(newProfile));
      if (!continueEditing) {
        onClose();
      }
    }
  };

  if (!newProfile) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <fieldset>
            <legend>Personal Details</legend>
            <div className="form-group">
              <label>Full Name:</label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                value={newProfile.fullName || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Preferred Name:</label>
              <input
                type="text"
                className="form-control"
                name="preferredName"
                value={newProfile.preferredName || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Professional Suffix:</label>
              <input
                type="text"
                className="form-control"
                name="professionalSuffix"
                value={newProfile.professionalSuffix || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <textarea
                className="form-control"
                name="description"
                value={newProfile.description || ''}
                onChange={handleChange}
              />
            </div>
          </fieldset>
        </div>

        <div className="col-md-6">
          <fieldset>
            <legend>Job Details</legend>
            <div className="form-group">
              <label>Department:</label>
              <input
                type="text"
                className="form-control"
                name="department"
                value={newProfile.department || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Job Title:</label>
              <input
                type="text"
                className="form-control"
                name="jobTitle"
                value={newProfile.jobTitle || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Hire Date:</label>
              <input
                type="date"
                className="form-control"
                name="hireDate"
                value={newProfile.hireDate || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Manager:</label>
              <input
                type="text"
                className="form-control"
                name="manager"
                value={newProfile.manager || ''}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Location:</label>
              <input
                type="text"
                className="form-control"
                name="location"
                value={newProfile.location || ''}
                onChange={handleChange}
              />
            </div>
          </fieldset>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary mr-2">
          Save
        </button>
        <button type="button" className="btn btn-secondary" onClick={() => setContinueEditing(!continueEditing)}>
          {continueEditing ? 'Finish Editing' : 'Continue Editing'}
        </button>
      </div>
    </form>
  );
};

export default EditEmployeeDetails;
