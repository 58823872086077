import React from 'react';
import ProfilePicture from '../../../components/Common/ProfilePicture';

import styles from './CalendarView.module.css';

interface ProfileSummaryProps {
  name?: string;
  jobTitle?: string;
  department?: string;
  team?: string;
  profilePicture?: string;
  remainingAllowance?: number;
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({ name, jobTitle, department, team, profilePicture, remainingAllowance }) => {
  return (
    <div className={styles.profileSummary}>
      <div className={styles.profilePicture}>
        <ProfilePicture 
          src={profilePicture ?? ''}
          alt="Profile"
        />
      </div>
      <div className={styles.profileDetails}>
        <div>{name}</div>
        <div>{jobTitle}</div>
      </div>
      <div className={styles.profileOtherInfo}>
        <div>Department: {department}</div>
        <div>Team: {team}</div>
        <div>Holiday runs from: <strong>January</strong> to <strong>December</strong></div>
        <div>Remaining Allowance: {remainingAllowance} days</div>
      </div>
    </div>
  );
};

export default ProfileSummary;
