import React from 'react';
import { useWizard } from 'react-use-wizard';

interface HeaderProps {
  stepTitles: string[];
}

const Header: React.FC<HeaderProps> = ({ stepTitles }) => {
  const { isFirstStep, isLastStep, previousStep, nextStep, stepCount, activeStep } = useWizard();

  // Display step number (1-based index)
  const currentStepLabel = `Step ${activeStep + 1} of ${stepCount}`;
  const title = stepTitles[activeStep] || 'Wizard';

  return (
    <header style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        padding: '1rem', 
        borderBottom: '1px solid #ccc', 
        backgroundColor: '#f5f5f5',
        height: '100px' // Adjust height as needed
    }}>
      <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '0.5rem' // Space between title and buttons
      }}>
        <div style={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center'
        }}>
          <h1 style={{ margin: 0, marginRight: '1rem' }}>{title}</h1>
          <h4 style={{ margin: 0 }}>{currentStepLabel}</h4>
        </div>
        <div></div> {/* Empty div for spacing */}
      </div>
      <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between'
      }}>
        <button onClick={() => previousStep()} disabled={isFirstStep} style={{ marginRight: '1rem' }}>
          Previous
        </button>
        <button onClick={() => nextStep()} disabled={isLastStep}>
          Next
        </button>
      </div>
    </header>
  );
};

export default Header;
