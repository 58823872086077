import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EditForm from '../../../components/Common/EditForm';
import FormInput from '../../../components/Common/FormInput';
import SaveButtonWithDropdown from '../../../components/Common/SaveButtonWithDropdown';
import { selectAddresses, updateEmployeeProfile } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { EmployeeProfile } from '../types/types';

const EditAddresses: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

  // Retrieve addresses from Redux state
  const employeeProfile = useSelector((state: RootState) => selectAddresses(state));
  const loading = useSelector((state: RootState) => state.employee.loading);
  const error = useSelector((state: RootState) => state.employee.error);

  // Local state for managing form data
  const [formData, setFormData] = useState<EmployeeProfile | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // Sync local state with Redux state when addresses change
  useEffect(() => {
    setFormData(employeeProfile);
  }, [employeeProfile]);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (formData) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent, continueEditing = false, setDropdownOpenCallback?: (open: boolean) => void) => {
    e.preventDefault();
    if (formData) {
      dispatch(updateEmployeeProfile(formData));
    }
    setDropdownOpen(false);
    if (!continueEditing) {
      onClose();
    }
    if (setDropdownOpenCallback) {
      setDropdownOpenCallback(false);
    }
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <EditForm loading={loading} error={error} onSubmit={handleSubmit}>
      <div className={`card mb-3`}>
        <div className="card-body" style={{ paddingTop: '20px' }}>
          <FormInput
            label={t('primary_address')}
            type="text"
            name="primaryAddress"
            value={formData.primaryAddress || ''}
            onChange={handleChange}
          />
          <FormInput
            label={t('secondary_address')}
            type="text"
            name="secondaryAddress"
            value={formData.secondaryAddress || ''}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <SaveButtonWithDropdown
          onSave={handleSubmit}
          loading={loading}
          saveLabel={t('save')}
          saveAndContinueLabel={t('save_and_continue')}
        />
      </div>
    </EditForm>
  );
};

export default EditAddresses;
