import { useEffect } from 'react';
import { AppDispatch } from './store/store';
import { useDispatch } from 'react-redux';
import { handleSilentLoginCallback } from './slices/authSlice';


export const SilentRenewComponent = () => {

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(handleSilentLoginCallback());
      }, [dispatch]);
    
      // const handleSilentLogin = () => {
      //   dispatch(handleSilentLoginCallback());
      // };

    //handleSilentLogin();
    return(
   
        <div>SilentRenewComponent</div>
    );
};