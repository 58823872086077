import React from 'react';
import { Link } from 'react-router-dom';

interface NotFoundProps {
  placeholder: string;
}

const NotFound: React.FC<NotFoundProps> = ({ placeholder }) => {
  return (
    <div className="content-body no-profile">
        <p>No {placeholder} found</p>
        <Link to="/" className="go-home-button">
        Go Home
      </Link>
    </div>
  );
};

export default NotFound;