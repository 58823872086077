import React from 'react';
import styles from './EditForm.module.css'

interface EditFormProps {
  loading: boolean;
  error: string | null;
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent, continueEditing?: boolean) => void;
}

const EditForm: React.FC<EditFormProps> = ({ loading, error, children, onSubmit }) => {
  return (
    <>
      {loading && (
        <div className={styles.modalOverlay}>
          <p>Updating...</p>
        </div>
      )}
      <form onSubmit={(e) => onSubmit(e)} className="form">
        {children}
        {error && <p className="text-danger">{error}</p>}
      </form>
    </>
  );
};

export default EditForm;
