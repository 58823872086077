// ProfilePicture.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import styles from './ProfilePicture.module.css'; // Assuming you have a CSS module for this component

interface ProfilePictureProps {
  src: string;
  alt: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src, alt, onChange, className }) => {
  return (
    <div className={`${styles.profilePicContainer} ${className}`}>
      <img src={src} alt={alt} className={styles.profilePic} />
      { onChange ? <input
        type="file"
        id="profilePicInput"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={onChange}
      /> : <></> }
      { onChange ? <label htmlFor="profilePicInput" className={styles.changePicIcon}>
        <FontAwesomeIcon icon={faCamera} />
      </label>: <></> }
    </div>
  );
};

export default ProfilePicture;
