import React, { useState, useEffect } from 'react';
import { AppDispatch, RootState } from '../../../../store/store';
import { selectCurrentUserProfile, updateUserProfile } from '../../slices/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../types/types';

const EditContactDetails: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const userProfile = useSelector((state: RootState) => selectCurrentUserProfile(state));
  const [continueEditing, setContinueEditing] = useState(false);
  const [newProfile, setNewProfile] = useState<UserProfile | null>(userProfile);

  useEffect(() => {
    if (userProfile) {
      setNewProfile(userProfile);
    }
  }, [userProfile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (newProfile) {
      setNewProfile({
        ...newProfile,
        contactDetails: { ...newProfile.contactDetails, [name]: value },
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newProfile) {
      await dispatch(updateUserProfile(newProfile));
      if (!continueEditing) {
        onClose();
      }
    }
  };

  if (!newProfile) return null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={newProfile.contactDetails?.email || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Phone:</label>
            <input
              type="tel"
              className="form-control"
              name="phone"
              value={newProfile.contactDetails?.phone || ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      {/* Add social links editing here */}

      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary mr-2">
          Save
        </button>
        <button type="button" className="btn btn-secondary" onClick={() => setContinueEditing(!continueEditing)}>
          {continueEditing ? 'Finish Editing' : 'Continue Editing'}
        </button>
      </div>
    </form>
  );
};

export default EditContactDetails;
