import React from 'react';
import styles from './Loading.module.css';

interface LoadingProps {
  loading: boolean;
  children?: React.ReactNode;
}

const Loading: React.FC<LoadingProps> = ({ loading, children }) => {
  return (
    <div className={styles.container}>
      {loading && (
        <div className={`${styles.contentBodyLoading} ${styles.loading}`}>
          <div className={styles.spinner}></div>
          <p>Loading...</p>
        </div>
      )}
      {children && <div className={styles.childrenBehindLoading}>{children}</div>}
    </div>
  );
};

export default Loading;
