
export interface Absence {
    id: string;
    employeeId: string;
    startDate: string;
    endDate: string;
    type: string; // e.g., 'holiday', 'sick', 'compassionate'
    status: string; // e.g., 'approved', 'pending'
  }
  
  export interface NonWorkingDay {
    date: string; // ISO format date
    type: 'publicHoliday' | 'bankHoliday' | 'weekend' | 'nonRotaDay';
    description?: string; // Optional description for the non-working day
  }
  
  export interface AbsencesState {
    absences: Absence[];
    nonWorkingDays: NonWorkingDay[];
    loading: boolean;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
  }
  
  export const initialState: AbsencesState = {
    absences: [],
    nonWorkingDays: [] as NonWorkingDay[],
    loading: false,
    status: 'idle',
    error: null,
  };