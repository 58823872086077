import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';

import { AuthContext } from './AuthContext';
import { login, logout, setUser, userManagerInstance } from './slices/authSlice';
import { RootState, AppDispatch } from './store/store';
import { useSelector, useDispatch } from 'react-redux';

import { faTachometer, faPager, faFaceGrinHearts, faNotdef, 
  faTable, faWind, faLock, faNoteSticky, faLinesLeaning, faStamp, 
  faFileInvoice, faBook, faUser, faUsers, faFileAlt, faChalkboardTeacher, 
  faMoneyBill, 
  faBell} from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@fortawesome/fontawesome-svg-core';
import { MenuItem } from './components/Layouts/types/types';
import { Dashboard, EmployeeProfile } from './features';
import { User } from 'oidc-client';
import { useTranslation } from 'react-i18next';
import OnboardingWizard from './features/employee/components/Onboarding/OnboardingWizard';
import { useUserData } from './features/user/hooks/useUserData';
import AlertList from './features/alerts/components/AlertList';
import MessageList from './features/messages/components/MessageList';
import CalendarView from './features/absence/components/CalendarView';
import TeamCalendarView from './features/absence/components/TeamCalendarView';
import { Routes, Route } from 'react-router-dom';
import { AutoLogin } from './utils/AutoLogin';
import Callback from './utils/Callback';
import {SilentRenewComponent as Renew } from './silentRenewComponent'


const App: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const userPrinciple = useSelector((state: RootState) => state.rootReducer.auth?.user);
  const userId = userPrinciple?.profile.sub || null;
 
  const loading = useSelector((state: RootState) => state.rootReducer.auth?.loading);

  const handleLogin = () => {
    dispatch(login());
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    userManagerInstance
      .getUser()
      .then((user: User | null) => {
        if (user) {
              dispatch(setUser(user));
        } 
      })
      .catch(() => 
    {
      console.log('Auth failure to handle');
        //Handle failures (or just let auto login try?)
    });
  }, [userManagerInstance, dispatch]);



    const { userProfile, status, error } = useUserData(userId);

    const routes: MenuItem[] = [
      {
        route: '/',
        component: Dashboard,
        title: t('dashboard'),
        subtitle: t('overview'),
        icon: faTachometer as Icon
      },
      {
        label: t('me'),
        icon: faUser as Icon,
        submenu: [
          {
            route: '/Employee/:employeeId',
            navPath: `/Employee/${userPrinciple?.profile.sub}`,
            component: EmployeeProfile,
            title: t('my-profile'),
            subtitle: t('view-profile'),
            icon: faPager as Icon
          },
          {
            route: '/Holidays',
            component: CalendarView,
            title: t('my-holidays'),
            subtitle: t('view-holidays'),
            icon: faFaceGrinHearts as Icon
          },
          {
            route: '/Blank',
            component: Dashboard,
            title: t('my-timesheets'),
            subtitle: t('view-timesheets'),
            icon: faFaceGrinHearts as Icon
          },
          {
            route: '/404',
            component: Dashboard,
            title: t('tasks'),
            subtitle: t('manage-tasks'),
            icon: faNotdef as Icon
          },
        ]
      },
      {
        label: t('my-teams'),
        icon: faUsers as Icon,
        submenu: [
          {
            route: '/team/holidays',
            component: TeamCalendarView,
            title: t('team-holidays'),
            subtitle: t('view-team-holidays'),
            icon: faTable as Icon
          },
          {
            route: '/table',
            component: Dashboard,
            title: t('team-notices'),
            subtitle: t('view-team-notices'),
            icon: faWind as Icon
          },
          {
            route: '/OnboardingWizard',
            component: OnboardingWizard,
            title: t('temp-onboarding'),
            subtitle: t('view-temp-onboarding'),
            icon: faWind as Icon
          },
          
        ]
      },
      {
        label: t('policies-docs'),
        icon: faFileAlt as Icon,
        submenu: [
          {
            route: '/auth/login',
            component: Dashboard,
            title: t('policies'),
            subtitle: t('view-policies'),
            icon: faLock as Icon
          },
          {
            route: '/auth/register',
            component: Dashboard,
            title: t('documents'),
            subtitle: t('view-documents'),
            icon: faNoteSticky as Icon
          },
        ]
      },
      {
        label: t('training'),
        icon: faChalkboardTeacher as Icon,
        submenu: [
          {
            route: '/auth/login',
            component: Dashboard,
            title: t('learning'),
            subtitle: t('view-learning'),
            icon: faLinesLeaning as Icon
          },
          {
            route: '/auth/register',
            component: Dashboard,
            title: t('compliance'),
            subtitle: t('view-compliance'),
            icon: faStamp as Icon
          },
        ]
      },
      {
        label: t('pay'),
        icon: faMoneyBill as Icon,
        submenu: [
          {
            route: '/auth/login',
            component: Dashboard,
            title: t('payslips'),
            subtitle: t('view-payslips'),
            icon: faFileInvoice as Icon
          },
          {
            route: '/auth/register',
            component: Dashboard,
            title: t('bank-details'),
            subtitle: t('view-bank-details'),
            icon: faBook as Icon
          }
        ]
      },
      {
        label: t('Notifications'),
        icon: faBell as Icon,
        submenu: [
          {
            route: '/alerts',
            component: AlertList,
            title: t('alerts'),
            subtitle: t('view-alerts'),
            icon: faBell as Icon
          },
          {
            route: '/messages/:threadId?',
            navPath: `/messages`,
            component: MessageList,
            title: t('messages'),
            subtitle: t('view-messages'),
            icon: faBook as Icon
          }
        ]
      },
    ];
    if (!userPrinciple?.expired)
      {
    return (
      <HelmetProvider>
        <AuthContext.Provider value={{ user: userPrinciple, loading, login: handleLogin, logout: handleLogout }}>
            <Layout routes={routes} userProfile={userProfile}/>
        </AuthContext.Provider>
      </HelmetProvider>
    );
  }
  else
  {
    userManagerInstance.startSilentRenew();
    
    return (
      <div>
      <Routes>
      <Route path="*" element={<AutoLogin user={userPrinciple} />} /> 
      <Route path="/callback" element={<Callback />} /> 
      <Route path="/renew" element={<Renew />} /> 
      </Routes>
  </div>
    )
  };
}

export default App;
