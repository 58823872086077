import React, { useState } from 'react';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment';
import AbsenceBookingForm from './AbsenceBookingForm';
import { useAbsenceData } from '../hooks/useAbsenceData';
import styles from './CalendarView.module.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AbsenceStatistics from './AbsenceStatistics';
import AbsenceList from './AbsenceList';
import { Absence } from '../types/types';
import CustomToolbar from './CustomToolbar';
import './CalendarCustom.scss';
import ProfileSummary from './ProfileSummary';
import { calculateAbsenceStatistics } from './calculateAbsenceStatistics';
const localizer = momentLocalizer(moment);

const employees = [
  { id: 'emp1', name: 'Alice', jobTitle: 'Developer', department: 'Engineering', team: 'Frontend', profilePicture: '' },
  { id: 'emp2', name: 'Bob', jobTitle: 'Designer', department: 'Design', team: 'UI/UX', profilePicture: '' },
  { id: 'emp3', name: 'Charlie', jobTitle: 'Manager', department: 'Management', team: 'Operations', profilePicture: '' },
];

const CalendarView: React.FC<{ viewOnly?: boolean, teamView?: boolean }> = ({ viewOnly = false, teamView = false }) => {
  const { absences, nonWorkingDays, loading, error } = useAbsenceData();
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [selectedRange, setSelectedRange] = useState<{ start: Date | null; end: Date | null }>({ start: null, end: null });
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(employees[0].id);
  const [contextMenu, setContextMenu] = useState<{ x: number, y: number, show: boolean, booking?: any }>({ x: 0, y: 0, show: false });
  const [calendarDate, setCalendarDate] = useState<Date | null>(new Date());

  const initialAllowance = 25; // Assume this is fetched or passed down somehow
  const remainingAllowance = 3; // Assume this is fetched or passed down somehow

  if (error) return <div>Error loading data: {error}</div>;

  const filteredAbsences = selectedEmployee
    ? absences.filter(abs => abs.employeeId === selectedEmployee)
    : absences;

  const absenceEvents = filteredAbsences.map(absence => ({
    title: absence.type,
    start: new Date(absence.startDate),
    end: new Date(absence.endDate),
    allDay: true,
    resource: absence,
  }));

  const nonWorkingDayEvents = nonWorkingDays.map(nonWorkingDay => ({
    title: nonWorkingDay.description || nonWorkingDay.type,
    start: new Date(nonWorkingDay.date),
    end: new Date(nonWorkingDay.date),
    allDay: true,
    resource: nonWorkingDay,
  }));

  const events = [...absenceEvents, ...nonWorkingDayEvents];

  const eventPropGetter = (event: any) => {
    const backgroundColor = event.resource.type === 'holiday' ? '#4682b4' :
                            event.resource.type === 'sick' ? '#50c878' :
                            '#ff5f5f';
    return { style: { backgroundColor, color: '#ffffff' } };
  };

  const handleSelectSlot = (slotInfo: { start: Date; end: Date }) => {
    if (!viewOnly) {
      setSelectedRange({
        start: slotInfo.start,
        end: slotInfo.end,
      });
      setContextMenu({ x: 0, y: 0, show: false });
      setShowBookingForm(true);
    }
  };

  const handleSelectEvent = (
    event: any,
    e: React.SyntheticEvent<HTMLElement>
  ) => {
    if (!viewOnly) {
      e.preventDefault();
      const nativeEvent = e.nativeEvent as MouseEvent;
      const x = nativeEvent.clientX;
      const y = nativeEvent.clientY;
      setContextMenu({ x, y, show: true, booking: event.resource });
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu({ x: 0, y: 0, show: false });
  };

  const handleEmployeeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEmployee(event.target.value);
  };

  const handleSelectAbsence = (absence: Absence) => {
    setSelectedRange({
      start: new Date(absence.startDate),
      end: new Date(absence.endDate),
    });
    setCalendarDate(new Date(absence.startDate));
  };

  const handleNavigate = (newDate: Date, view: View) => {
    setCalendarDate(newDate);
  };

  const selectedEmployeeDetails = employees.find(emp => emp.id === selectedEmployee);
  
  const { remainingLeave } = calculateAbsenceStatistics(filteredAbsences, initialAllowance);

  return (
    <div className={styles.container}>
      <div className={styles.calendarContainer}>
        {teamView && (<h2>Holidays & Absences</h2>)}
        {!teamView && (
                   <ProfileSummary
                   name={selectedEmployeeDetails?.name}
                   jobTitle={selectedEmployeeDetails?.jobTitle}
                   department={selectedEmployeeDetails?.department}
                   team={selectedEmployeeDetails?.team}
                   profilePicture={selectedEmployeeDetails?.profilePicture}
                   remainingAllowance={remainingLeave}
                 />
        )}

        {teamView && (
          <div className={styles.employeeFilter}>
            <label className={styles.employeeFilterLabel}>
              Select Employee:
              <select 
                className={styles.employeeFilterSelect} 
                value={selectedEmployee || ''} 
                onChange={handleEmployeeChange}
              >
                <option value="">All Employees</option>
                {employees.map(emp => (
                  <option key={emp.id} value={emp.id}>
                    {emp.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}
        <Calendar
          date={calendarDate || new Date()}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          onNavigate={handleNavigate}
          components={{
            toolbar: CustomToolbar,
            
          }}
          eventPropGetter={eventPropGetter}
        />
      </div>
      <div className={styles.sidebar}>
        <AbsenceList absences={filteredAbsences} onSelectAbsence={handleSelectAbsence} />
        <AbsenceStatistics employeeId={selectedEmployee || undefined} teamView={teamView} totalAllowance={initialAllowance} />
        <div className={styles.legend}>
          <h4>Legend</h4>
          <div><span className={styles.workEvent}></span> Work</div>
          <div><span className={styles.sickEvent}></span> Sick Leave</div>
          <div><span className={styles.holidayEvent}></span> Public Holiday</div>
        </div>
      </div>
      <AbsenceBookingForm
        startDate={selectedRange.start}
        endDate={selectedRange.end}
        nonWorkingDays={nonWorkingDays}  
        absences={absences}  
        onClose={() => setShowBookingForm(false)}
        allowance={remainingAllowance} 
        allowPurchaseMoreHolidays={true}
        show={showBookingForm} 
      />
      {contextMenu.show && (
        <div
          className={styles.contextMenu}
          style={{ top: contextMenu.y, left: contextMenu.x }}
          onClick={handleContextMenuClose}
        >
          <ul>
            {contextMenu.booking?.type === 'publicHoliday' ? (
              <li>Public Holiday</li>
            ) : contextMenu.booking ? (
              <>
                <li onClick={() => { setShowBookingForm(true); setContextMenu({ ...contextMenu, show: false }); }}>Edit</li>
                <li>Cancel</li>
              </>
            ) : (
              <li onClick={() => { setShowBookingForm(true); setContextMenu({ ...contextMenu, show: false }); }}>Book</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CalendarView;
