import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UserManager, User } from 'oidc-client';

import { getAuthConfig } from '../config/configManager';
import Cookies from 'universal-cookie';


// Initialize the OpenID Connect client
const userManager = new UserManager(getAuthConfig());
const cookies = new Cookies();

export const userManagerInstance = userManager

// Define the async thunk action for handling the login process
export const login = createAsyncThunk('auth/login', async () => {

  userManager.getUser().then(async (user)=> {
      if (!user)
        {
          await userManager.signinRedirect();
        }

      if (user?.expired)
        {
          await userManager.signinRedirect();
        }
  });


});

// Define the async thunk action for handling the callback
export const handleLoginCallback = createAsyncThunk(
  'auth/handleLoginCallback', 
  async () => {
  const user = await userManager.signinRedirectCallback();
  cookies.remove('hasTriedSignin');
  return user;
});

// Define the async thunk action for handling the callback
export const silentLogin = createAsyncThunk('auth/silentLogin', async () => {
  await userManager.signinSilent();
});

// Define the async thunk action for handling the callback
export const handleSilentLoginCallback = createAsyncThunk('auth/handleSilentLoginCallback', async () => {

  await userManager.signinSilentCallback();
});

// Define the async thunk action for handling the logout process
export const logout = createAsyncThunk('auth/logout', async () => {
  await userManager.signoutRedirect();
});

// Define the auth slice with initial state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null as User | null,
    loading: false,
    error: null as string | null,
  },
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(handleLoginCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleLoginCallback.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(handleLoginCallback.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(handleSilentLoginCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleSilentLoginCallback.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(handleSilentLoginCallback.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message  ?? null;
      });
  },
});

export const { setUser, setLoading } = authSlice.actions;
export default authSlice.reducer;
