import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../store/store';
import { Education, SalaryHistory, EmployeeState, WorkExperienceHistory, ContactDetails, EmployeeProfile, ContactInfo } from '../types/types';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/employeeMockApi') : require('../api/employeeApi');

interface FetchEmployeeProfileParams {
  token: string;
  employeeId: string;
}

// Selectors for current employee profile
export const selectCurrentEmployeeProfile = (state: RootState) => 
  state.employee.profiles[state.employee.currentEmployeeId || ''];

// Selectors based on current employee profile
export const selectEducationHistory = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.educationHistory || []
);

export const selectDependents = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.dependents || []
);

export const selectSalaryHistory = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.salaryHistory || []
);

export const selectWorkExperienceHistory = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.workExperienceHistory || []
);

export const selectEmergencyContacts = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.emergencyContacts || []
);

export const selectPersonalDetails = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile || null
);

export const selectAddresses = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile || null
);

export const selectContactDetails = createSelector(
  [selectCurrentEmployeeProfile],
  (employeeProfile) => employeeProfile?.contactDetails || null
);

export const fetchEmployeeProfile = createAsyncThunk<
  { id: string; profile: EmployeeProfile }, // Return both ID and profile
  FetchEmployeeProfileParams,
  { rejectValue: string }
>(
  'employee/fetchEmployeeProfile',
  async ({ token, employeeId }, { rejectWithValue }) => {
    try {
      const profile: EmployeeProfile = await api.fetchEmployeeProfile(token, employeeId);
      return { id: employeeId, profile };
    } catch (error) {
      return rejectWithValue('Failed to fetch employee profile');
    }
  }
);

export const updateEmployeeProfile = createAsyncThunk<
  EmployeeProfile,
  EmployeeProfile,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEmployeeProfile',
  async (newProfile, { dispatch, getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    await api.updateEmployeeProfile(accessToken, newProfile);
    return newProfile;
  }
);

export const updateContactDetails = createAsyncThunk<
  ContactDetails | null,
  ContactDetails | null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateContactDetails',
  async (newContactDetails, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      contactDetails: newContactDetails,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newContactDetails;
  }
);

export const updateEducationHistory = createAsyncThunk<
  Education[],
  Education[],
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEducationHistory',
  async (newEducationHistory, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      educationHistory: newEducationHistory,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newEducationHistory;
  }
);

export const updateDependents = createAsyncThunk<
ContactInfo[],
ContactInfo[],
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateDependents',
  async (newDependents, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      dependents: newDependents,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newDependents;
  }
);

export const updateSalaryHistory = createAsyncThunk<
  SalaryHistory[],
  SalaryHistory[],
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateSalaryHistory',
  async (newSalaryHistory, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      salaryHistory: newSalaryHistory,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newSalaryHistory;
  }
);

export const updateWorkExperience = createAsyncThunk<
  WorkExperienceHistory[],
  WorkExperienceHistory[],
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateWorkExperience',
  async (newWorkExperience, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      workExperienceHistory: newWorkExperience,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newWorkExperience;
  }
);

export const updateEmergencyContacts = createAsyncThunk<
  ContactInfo[],
  ContactInfo[],
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEmergencyContacts',
  async (newEmergencyContacts, { dispatch, getState }) => {
    const state = getState();
    const updatedProfile: EmployeeProfile = {
      ...state.employee.profiles[state.employee.currentEmployeeId!],
      emergencyContacts: newEmergencyContacts,
    };
    await dispatch(updateEmployeeProfile(updatedProfile));
    return newEmergencyContacts;
  }
);

const initialState: EmployeeState = {
  profiles: {},
  currentEmployeeId: null,
  status: 'idle',
  loading: false,
  error: null
};

const employeeDetailsSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setCurrentEmployeeId(state, action: PayloadAction<string | null>) {
      state.currentEmployeeId = action.payload;
    },
    setStatusLoading(state) {
      state.status = 'loading';
    },
    setStatusSucceeded(state) {
      state.status = 'succeeded';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployeeProfile.fulfilled, (state, action) => {
        const { id, profile } = action.payload;
        state.status = 'succeeded';
        state.profiles[id] = profile;
        state.currentEmployeeId = id;
      })
      .addCase(fetchEmployeeProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch employee profile';
      })
      .addCase(updateEmployeeProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmployeeProfile.fulfilled, (state, action: PayloadAction<EmployeeProfile>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateEmployeeProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update employee profile';
      })
      .addCase(updateContactDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContactDetails.fulfilled, (state, action: PayloadAction<ContactDetails | null>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId].contactDetails = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateContactDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update contact details';
      })
      .addCase(updateEducationHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEducationHistory.fulfilled, (state, action: PayloadAction<Education[]>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId].educationHistory = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateEducationHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update education history';
      })
      .addCase(updateSalaryHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSalaryHistory.fulfilled, (state, action: PayloadAction<SalaryHistory[]>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId].salaryHistory = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateSalaryHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update salary history';
      })
      .addCase(updateWorkExperience.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateWorkExperience.fulfilled, (state, action: PayloadAction<WorkExperienceHistory[]>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId].workExperienceHistory = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateWorkExperience.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update work experience';
      })
      .addCase(updateEmergencyContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmergencyContacts.fulfilled, (state, action: PayloadAction<ContactInfo[]>) => {
        if (state.currentEmployeeId) {
          state.profiles[state.currentEmployeeId].emergencyContacts = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateEmergencyContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update emergency contacts';
      });
  },
});

export const { setCurrentEmployeeId, setStatusLoading, setStatusSucceeded } = employeeDetailsSlice.actions;
export default employeeDetailsSlice.reducer;
