import React, { useState, useEffect } from 'react';
import { bookAbsence } from '../slices/absencesSlice';
import { Absence, NonWorkingDay } from '../types/types';
import styles from './AbsenceBookingForm.module.css';
import { formatDateToLocalISO } from '../../../utils/dateUtils';
import { useAppDispatch } from '../../../app/hooks';

interface AbsenceBookingFormProps {
  startDate: Date | null;
  endDate: Date | null;
  nonWorkingDays: NonWorkingDay[];
  absences: Absence[];
  allowance: number;
  allowPurchaseMoreHolidays: boolean;
  onClose: () => void;
  show: boolean; // New prop to manage visibility
}

const AbsenceBookingForm: React.FC<AbsenceBookingFormProps> = ({ startDate, endDate, nonWorkingDays, absences, allowance, allowPurchaseMoreHolidays, onClose, show }) => {
  const dispatch = useAppDispatch();
  const [employeeId, setEmployeeId] = useState('');
  const [start, setStart] = useState<string>(startDate ? formatDateToLocalISO(startDate) : '');
  const [end, setEnd] = useState<string>(endDate ? formatDateToLocalISO(endDate) : '');
  const [type, setType] = useState('holiday');
  const [daysOff, setDaysOff] = useState(0);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);

  useEffect(() => {
    if (startDate) setStart(formatDateToLocalISO(startDate));
    if (endDate) setEnd(formatDateToLocalISO(endDate));
    if (startDate && endDate) {
      const daysOffCount = calculateWorkingDays(startDate, endDate);
      setDaysOff(daysOffCount);

      if (daysOffCount > allowance) {
        setValidationMessage(`You have requested ${daysOffCount} days, but only have ${allowance} days remaining.`);
      } else {
        setValidationMessage(null);
      }
    }
  }, [startDate, endDate, nonWorkingDays, absences, allowance]);

  const isNonWorkingOrAbsentDay = (date: Date) => {
    return (
      nonWorkingDays.some(nonWorkingDay => {
        const nonWorkingDate = new Date(nonWorkingDay.date);
        nonWorkingDate.setHours(0, 0, 0, 0);
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
        return nonWorkingDate.getTime() === normalizedDate.getTime();
      }) ||
      absences.some(absence => {
        const absenceStart = new Date(absence.startDate);
        const absenceEnd = new Date(absence.endDate);
        absenceStart.setHours(0, 0, 0, 0);
        absenceEnd.setHours(0, 0, 0, 0);
        return date >= absenceStart && date <= absenceEnd;
      })
    );
  };

  const calculateWorkingDays = (start: Date, end: Date) => {
    let count = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
      if (!isNonWorkingOrAbsentDay(currentDate)) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (daysOff > allowance) {
      setValidationMessage(`Cannot book ${daysOff} days as it exceeds your remaining allowance of ${allowance} days.`);
      return;
    }

    const newAbsence: Absence = {
      id: '', // ID will be generated in the backend or mock
      employeeId,
      startDate: start,
      endDate: end,
      type,
      status: 'pending',
    };
    dispatch(bookAbsence(newAbsence));
    onClose();
  };

  return (
    <div className={`${styles.absenceBookingForm} ${show ? styles.show : ''}`}>
      <form onSubmit={handleSubmit}>
        <h2>Book Absence</h2>
        <p>Your allowance: {allowance} days</p>
        <p>This booking will use: {daysOff} days</p>
        <p>Remaining allowance after booking: {allowance - daysOff >= 0 ? allowance - daysOff : 0} days</p>

        {validationMessage && (
          <p className={styles.validationMessage}>{validationMessage}</p>
        )}

        <label>
          Employee ID:
          <input type="text" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} required />
        </label>
        <label>
          Start Date:
          <input type="date" value={start} onChange={(e) => setStart(e.target.value)} required />
        </label>
        <label>
          End Date:
          <input type="date" value={end} onChange={(e) => setEnd(e.target.value)} required />
        </label>
        <label>
          Type:
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="holiday">Holiday</option>
            <option value="sick">Sick</option>
            <option value="compassionate">Compassionate Leave</option>
            <option value="toil">TOIL</option>
          </select>
        </label>

        <button className='btn btn-success' type="submit" disabled={daysOff > allowance}>Submit</button>
        <button className='btn btn-secondary' type="button" onClick={onClose}>Cancel</button>

        {allowPurchaseMoreHolidays && daysOff > allowance && (
          <button className='btn btn-primary' type="button" onClick={() => alert('Redirect to purchase more holidays')}>
            Buy More Holidays
          </button>
        )}
      </form>
    </div>
  );
};

export default AbsenceBookingForm;
