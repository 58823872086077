// Mock API service
export const fetchAnnualLeaveStats = async (token: string)  => {
    // Mock data
    return {
      awaitingApproval: 1,
      approved: 2,
      taken: 3,
    };
  };
  
  export const fetchRequestsAndTasks = async (token: string)  => {
    // Mock data
    return [
      { id: 'employee-on-boarding-3', type: 'IT Task', description: 'Setup workstation', dueDate: '2023-08-01', issuedBy: 'Admin' },
      { id: 'employee-on-boarding-2', type: 'IT Task', description: 'Install software', dueDate: '2023-08-02', issuedBy: 'Admin' },
      { id: 'employee-on-boarding-1', type: 'IT Task', description: 'Network configuration', dueDate: '2023-08-03', issuedBy: 'Admin' },
    ];
  };
  
  export const fetchDaysSinceLastHoliday = async (token: string)  => {
    // Mock data
    return [
      { name: 'Matthew', days: 15 },
      { name: 'Mia', days: 10 },
      { name: 'Ryan', days: 20 },
    ];
  };
  