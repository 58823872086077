import React from 'react';
import { useDispatch } from 'react-redux';
import { Absence } from '../types/types';
import styles from './AbsenceList.module.css';

interface AbsenceListProps {
  absences: Absence[];
  onSelectAbsence: (absence: Absence) => void;
}

const formatDateRange = (startDate: string, endDate: string) => {
  const start = new Date(startDate).toLocaleDateString();
  const end = new Date(endDate).toLocaleDateString();
  return `${start} - ${end}`;
};

const calculateTotalDays = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
};

const AbsenceList: React.FC<AbsenceListProps> = ({ absences, onSelectAbsence }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.absenceList}>
      <h4>Holidays Booked</h4>
      <ul>
        {absences.map(absence => (
          <li key={absence.id} onClick={() => onSelectAbsence(absence)} className={styles.absenceItem}>
            <div>{formatDateRange(absence.startDate, absence.endDate)}</div>
            <div>{calculateTotalDays(absence.startDate, absence.endDate)} Days</div>
            <div>{absence.type}</div>
            <div>Status: {absence.status}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AbsenceList;
