// src/store/navbarSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
  isPinned: boolean;
}

const initialState: NavbarState = {
  isPinned: false,
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    togglePinned: (state) => {
      state.isPinned = !state.isPinned;
    },
    setPinned: (state, action: PayloadAction<boolean>) => {
      state.isPinned = action.payload;
    },
  },
});

export const { togglePinned, setPinned } = navbarSlice.actions;

export default navbarSlice.reducer;
