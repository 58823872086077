import React from 'react';

interface StepWrapperProps {
  children: React.ReactNode;
}

const StepWrapper: React.FC<StepWrapperProps> = ({ children }) => {
  return (
    <div style={{ padding: '1rem' }}>
      {children}
    </div>
  );
};

export default StepWrapper;
