import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSalaryHistory, updateSalaryHistory } from '../../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../../store/store';
import { SalaryHistory } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../../components/Common/Carousel';
import styles from '../Edit.module.css';

const EditSalaryHistory: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();
  const salaryHistory = useSelector((state: RootState) => selectSalaryHistory(state));
  const loading = useSelector((state: RootState) => state.employee.loading);
  const error = useSelector((state: RootState) => state.employee.error);

  const [newSalaryHistory, setNewSalaryHistory] = useState<SalaryHistory[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setNewSalaryHistory(salaryHistory);
  }, [salaryHistory]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedSalaryHistory = [...newSalaryHistory];
    updatedSalaryHistory[index] = { ...updatedSalaryHistory[index], [name]: value };
    setNewSalaryHistory(updatedSalaryHistory);
  };

  const handleAdd = () => {
    const updatedSalaryHistory = [...newSalaryHistory, { amount: 0, effectiveDate: '' }];
    setNewSalaryHistory(updatedSalaryHistory);
    setCurrentIndex(updatedSalaryHistory.length - 1);
  };

  const handleRemove = (index: number) => {
    const updatedSalaryHistory = newSalaryHistory.filter((_, i) => i !== index);
    setNewSalaryHistory(updatedSalaryHistory);
    setCurrentIndex((prevIndex) => (prevIndex >= updatedSalaryHistory.length ? updatedSalaryHistory.length - 1 : prevIndex));
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    dispatch(updateSalaryHistory(newSalaryHistory));
    setDropdownOpen(false);  // Close the dropdown
    if (!continueEditing) {
      onClose();
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const salaryItems = newSalaryHistory?.map((salary: SalaryHistory, index: number) => (
    <div key={index} className={`card mb-3 ${styles.card}`}>
      <div className="card-body d-flex justify-content-between align-items-start">
        <div className="w-100" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('salary_history_component.amount')}:</label>
            <input
              type="number"
              className="form-control"
              name="amount"
              value={salary.amount}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('salary_history_component.effective_date')}:</label>
            <input
              type="date"
              className="form-control"
              name="effectiveDate"
              value={salary.effectiveDate}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => handleRemove(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ));

  return (
    <>
      {loading && (
        <div className={styles.modalOverlay}>
          <p>{t('salary_history_component.updating')}</p>
        </div>
      )}
      <form className={styles.form}>
        <Carousel items={salaryItems} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
          <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
            <FontAwesomeIcon icon={faPlus} /> {t('salary_history_component.add_new')}
          </button>
          <div className="btn-group">
            <button className="btn btn-success" type="submit" onClick={(e) => handleSubmit(e)} disabled={loading}>
              {t('salary_history_component.save')}
            </button>
            <button
              className="btn btn-success dropdown-toggle dropdown-toggle-split"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            >
            </button>
            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
              <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
                {t('salary_history_component.save_and_continue')}
              </button>
            </div>
          </div>
        </div>
        {error && <p className="text-danger">{error}</p>}
      </form>
    </>
  );
};

export default EditSalaryHistory;
