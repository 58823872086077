import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { fetchEmployeeProfile, updateEmployeeProfile, selectCurrentEmployeeProfile, setCurrentEmployeeId, setStatusLoading, setStatusSucceeded } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { EmployeeProfile } from '../types/types';

export const useEmployeeData = (employeeId: string | null) => {

  const dispatch = useDispatch<AppDispatch>();
  const currentEmployeeProfile = useSelector((state: RootState) => selectCurrentEmployeeProfile(state));
  const status = useSelector((state: RootState) => state.employee.status);
  const error = useSelector((state: RootState) => state.employee.error);
  const user = useSelector((state: RootState) => state.rootReducer.auth?.user);

  const userAccessToken = user?.access_token ?? '';

  const profiles = useSelector((state: RootState) => state.employee.profiles);

  const [newProfile, setNewProfile] = useState<EmployeeProfile>(currentEmployeeProfile);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'failed')
      {
        throw new Error('Oops, something went wrong!');
      }
    if (employeeId !== null) {
      // Dispatch the action to set status to 'loading'
      dispatch(setStatusLoading());
  
      // Fetch the employee profile if it's not in the state or if the status is idle
      const currentProfile = profiles[employeeId];
      if (status === 'idle' || !currentProfile) {
        dispatch(fetchEmployeeProfile({ employeeId, token: userAccessToken }));
      }
      // Set the current employee ID
      dispatch(setCurrentEmployeeId(employeeId));
    }
  
    // Optionally update some local state or effect
  
  }, [status, dispatch, employeeId, userAccessToken, profiles, currentEmployeeProfile]);

  useEffect(() => {
      setNewProfile(currentEmployeeProfile);
      dispatch(setStatusSucceeded());
  }, [currentEmployeeProfile, dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (newProfile) {
      setNewProfile({ ...newProfile, [name]: value });
    }
  };

  const handleSubmit = async (
    e: React.FormEvent,
    continueEditing: boolean,
    onClose: () => void,
    setDropdownOpen: (open: boolean) => void
  ) => {
    e.preventDefault();
    if (newProfile) {
      setSaving(true);
      await dispatch(updateEmployeeProfile(newProfile));
      setSaving(false);
      setDropdownOpen(false); // Close the dropdown
      if (!continueEditing) {
        onClose();
      }
    }
  };

  return {
    employeeProfile: newProfile,
    status,
    error,
    handleChange,
    handleSubmit,
    loading: status === 'loading' || saving
  };
};
