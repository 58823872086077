import { EmployeeProfile } from '../types/types'; // Assuming you have a types file
import { faTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

export const fetchEmployeeProfile = async (): Promise<EmployeeProfile> => {
  // Simulate API call delay
  //  await new Promise(resolve => setTimeout(resolve, 1000));

  // Mock employee profile data
  const mockEmployeeProfile: EmployeeProfile = {
    id: 'adbf90b6-b65e-474a-9746-5d6cdbee4cb1',
  fullName: 'John Smith',
  dateOfBirth: '1990-01-01',
  gender: 'Male',
  maritalStatus: 'Single',
  nationality: 'American',
  department: 'Engineering',
  jobTitle: 'Software Engineer',
  hireDate: '2015-01-01',
  terminationDate: null,
  manager: 'John Doe',
  emergencyContacts: [
    { name: 'Superman', relationship: 'Family', phoneNumber: '123-456-7890' },
    { name: 'Batman', relationship: 'Friend', phoneNumber: '987-654-3210' }
  ],
  nextOfKin: [
    { name: 'Next of Kin 1', relationship: 'Family', phoneNumber: '111-222-3333' }
  ],
  dependents: [
    { name: 'James Bond', relationship: 'Spy', phoneNumber: '007' },
    { name: 'Mr Blobby', relationship: 'Entertainer', phoneNumber: '0207' }
  ],
  educationHistory: [
    { degree: 'Bachelor of Science', institution: 'University A', graduationDate: '2012-05-01' },
    { degree: 'GCSE', institution: 'School', graduationDate: '2008-05-01' }
  ],
  workExperienceHistory: [
    { employer: 'Company A', jobTitle: 'Software Engineer', startDate: '2012-06-01', endDate: '2014-06-01' },
    { employer: 'Company B', jobTitle: 'Tech Software Engineer', startDate: '2012-06-01', endDate: '2014-06-01' }
  ],
  salaryHistory: [
    { amount: 80000, effectiveDate: '2015-01-01' }
  ],
  terminationReason: '',
  profilePicture: 'https://i.pravatar.cc/150?img=1',
  location: 'San Francisco, CA',
  description: 'Hey! My name is John and I am a software engineer at Company Ltd. since January 2015. As part of the Engineering department, my work involves developing software applications.',
  contactDetails: {
    phone: '123-456-7890',
    email: 'john.smith@example.com'
  },
  socialLinks: [
    { platform: 'Twitter', url: 'https://twitter.com/johnsmith', icon: faTwitter },
    { platform: 'LinkedIn', url: 'https://linkedin.com/in/johnsmith', icon: faLinkedin },
    { platform: 'GitHub', url: 'https://github.com/johnsmith', icon: faGithub }
  ],
  skills: ['JavaScript', 'React', 'Node.js'],
  knowledgeAreas: ['Frontend Development', 'Backend Development'],
  projects: [
    { name: 'Project A', description: 'A project to develop a web application.' },
    { name: 'Project B', description: 'A project to develop a mobile application.' }
  ],
  equipment: [
    { name: 'Laptop', details: 'MacBook Pro' },
    { name: 'Monitor', details: 'Dell 24-inch' }
  ],
  benefits: [
    { name: 'Health Insurance', description: 'Comprehensive health insurance plan.' },
    { name: 'Retirement Plan', description: '401(k) retirement savings plan.' }
  ],
  certificates: [
    { name: 'Certified JavaScript Developer', issuedBy: 'Tech Institute', issueDate: '2021-05-01' }
  ],
  shoeSize: '',
  tShirtSize: '',
  uniformSize: '',
  primaryAddress: '',
  secondaryAddress: '',
  taxID: '',
  preferredName: '',
  professionalSuffix: '',
  payrollName: '',
  status: ''

  };

  return mockEmployeeProfile;
};