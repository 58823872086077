import { UserProfile } from "../types/types";
import { getAppConfig } from '../../../config/configManager';
import axiosRetry from 'axios-retry';
import axios from "axios";

// Retry failed requests up to 3 times
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
const API_BASE_URL = getAppConfig().baseUrl;

const fetchUserProfile = async (token: string, id: string): Promise<UserProfile> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/${id}/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data as UserProfile;  // Axios automatically parses the JSON
  } catch (error) {
    throw new Error('Failed to fetch user profile');
  }
};

const updateUserProfile = async (token: string, newProfile: UserProfile) => {
  try {
    await axios.put(`${API_BASE_URL}/user/${newProfile.id}/profile`, newProfile, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to update user profile');
  }
};

export { fetchUserProfile, updateUserProfile };
