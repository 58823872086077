import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { fetchAlerts } from '../api/alertsMockApi';
import { Alert } from '../types/alertTypes';

export interface AlertsState {
  alerts: Alert[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AlertsState = {
  alerts: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch alerts
export const fetchAlertsThunk = createAsyncThunk('alerts/fetchAlerts', async () => {
  const response = await fetchAlerts();
  return response;
});

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    dismissAlert(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload);
    },
    pinAlert(state, action: PayloadAction<string>) {
      const alert = state.alerts.find(alert => alert.id === action.payload);
      if (alert) {
        alert.pinned = true;
      }
    },
    unpinAlert(state, action: PayloadAction<string>) {
      const alert = state.alerts.find(alert => alert.id === action.payload);
      if (alert) {
        alert.pinned = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAlertsThunk.fulfilled, (state, action: PayloadAction<Alert[]>) => {
        state.status = 'succeeded';
        state.alerts = action.payload;
      })
      .addCase(fetchAlertsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch alerts';
      });
  },
});

// Selectors
export const selectAllAlerts = (state: RootState) => state.alerts.alerts;
export const selectAlertStatus = (state: RootState) => state.alerts.status;
export const selectAlertError = (state: RootState) => state.alerts.error;

export const { dismissAlert, pinAlert, unpinAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
