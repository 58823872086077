import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { logout } from '../../slices/authSlice';
import { MenuItem } from './types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faFileAlt, faDonate, faExclamationTriangle, faEnvelope, faUser, faCogs, faList, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './Header.module.css';
import { AppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Common/Modal';
import UserProfile from '../../features/user/components/UserProfile';
import Settings from '../../features/settings/components/Settings';
import ActivityLog from '../../features/activityLog/components/ActivityLog';
import { UserProfile as UserProfileType } from '../../features/user/types/types';
import { format } from 'date-fns';
import { useAlertData } from '../../features/alerts/hooks/useAlertData';
import { useMessageData } from '../../features/messages/hooks/useMessageData';

interface HeaderProps {
  routes: MenuItem[];
  user: UserProfileType;
}

const Header: React.FC<HeaderProps> = ({ routes, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { alerts } = useAlertData(); // Trigger loading of alert data
  const { unreadThreads } = useMessageData(); // Trigger loading of message data

  const unreadMessagesByThread = unreadThreads; //TODO get current users id, TODO push unread to the slice

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = (menuItem: string) => {
    setOpenDropdown(null);

    switch (menuItem) {
      case 'profile':
        setIsProfileModalOpen(true);
        break;
      case 'settings':
        setIsSettingsModalOpen(true);
        break;
      case 'activity-log':
        setIsActivityLogModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseProfileModal = () => setIsProfileModalOpen(false);
  const handleCloseSettingsModal = () => setIsSettingsModalOpen(false);
  const handleCloseActivityLogModal = () => setIsActivityLogModalOpen(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const currentLocation = location.pathname.toLowerCase();

  let route: MenuItem | undefined;
  
  // Helper function to check if the currentLocation starts with a given path
  const isMatchingPath = (path?: string) => path && currentLocation.startsWith(path.toLowerCase());
  
  const findRoute = (items: MenuItem[]): MenuItem | undefined => {
    for (const item of items) {
  
      if (isMatchingPath(item?.route)) {
        return item;
      }
  
      if (isMatchingPath(item?.navPath)) {
        return item;
      }
    }
    return undefined;
  };
  
  // Check submenus first, filtering out undefined submenu items
  const submenuItems = routes.flatMap(x => x.submenu).filter((item): item is MenuItem => !!item);
  route = findRoute(submenuItems);
  
  // If no route is found in submenus, check the main routes
  if (!route) {
    route = findRoute(routes);
  }
  
  if (!route) return null;
  
  
  // Further code using the `route` object

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
      <div className="pl-4 d-none d-sm-inline-block" style={{ textWrap: "nowrap", width: "25em" }}>
        <h1 style={{ display: "inline" }} className="font-semibold text-3xl">{route.title}</h1> &nbsp;
        <h3 style={{ display: "inline" }} className="text-sm text-gray-500">{route.subtitle}</h3>
      </div>

      <form className={`d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 ${styles.search}`}>
        <div className="input-group">
          <input type="text" className="form-control bg-light border-0 small" placeholder={t('search-for')} aria-label="Search" aria-describedby="basic-addon2" />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <FontAwesomeIcon icon={faSearch} className="fa-sm" />
            </button>
          </div>
        </div>
      </form>

      <ul className="navbar-nav ml-auto">
        <li className={`nav-item dropdown no-arrow mx-1 ${openDropdown === 'alerts' ? 'show' : ''}`}>
          <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" onClick={() => toggleDropdown('alerts')} aria-haspopup="true" aria-expanded={openDropdown === 'alerts'}>
            <FontAwesomeIcon icon={faBell} className="fa-fw" />
            <span className="badge badge-danger badge-counter">{alerts.length > 0 ? `${alerts.length}+` : '0'}</span>
          </a>
          <div className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in ${openDropdown === 'alerts' ? 'show' : ''}`} aria-labelledby="alertsDropdown">
            <h6 className="dropdown-header">{t('alerts-center')}</h6>
            {alerts.slice(0, 3).map((alert) => (
              <Link key={alert.id} className="dropdown-item d-flex align-items-center" to="/alerts" onClick={() => toggleDropdown('alerts')}>
                <div className="mr-3">
                  <div className="icon-circle bg-primary">
                    <FontAwesomeIcon icon={faFileAlt} className="text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">{format(new Date(alert.timestamp), 'MMMM d, yyyy')}</div>
                  <span className="font-weight-bold">{alert.title}</span>
                </div>
              </Link>
            ))}
            {alerts.length > 3 && (
              <Link className="dropdown-item text-center small text-gray-500" to="/alerts">{t('show-all-alerts')}</Link>
            )}
            {alerts.length === 0 && (
              <Link className="dropdown-item text-center small text-gray-500" to="/alerts">{t('goto-alerts')}</Link>
            )}
          </div>
        </li>

        <li className={`nav-item dropdown no-arrow mx-1 ${openDropdown === 'messages' ? 'show' : ''}`}>
  <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" onClick={() => toggleDropdown('messages')} aria-haspopup="true" aria-expanded={openDropdown === 'messages'}>
    <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
    <span className="badge badge-danger badge-counter">
      {Object.keys(unreadMessagesByThread).length > 0 ? `${Object.keys(unreadMessagesByThread).length}+` : '0'}
    </span>
  </a>
  <div className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in ${openDropdown === 'messages' ? 'show' : ''}`} aria-labelledby="messagesDropdown">
    <h6 className="dropdown-header">{t('message-center')}</h6>
    {Object.values(unreadMessagesByThread).slice(0, 3).map((message) => (
      <Link key={message.id} className="dropdown-item d-flex align-items-center" to={`/messages/${message.threadId}`} onClick={() => toggleDropdown('messages')}>
        <div className="dropdown-list-image mr-3">
          <img className="rounded-circle" src={message.sender.profilePicture || './icon.png'} alt={message.sender.name} />
          <div className={`status-indicator ${message.sender.isOnline ? 'bg-success' : ''}`}></div>
        </div>
        <div className="font-weight-bold">
          <div className="text-truncate">{message.content}</div>
          <div className="small text-gray-500">{message.sender.name} · {format(new Date(message.timestamp), 'p')}</div>
        </div>
      </Link>
    ))}
    {Object.keys(unreadMessagesByThread).length > 3 && (
      <Link className="dropdown-item text-center small text-gray-500" to="/messages">{t('read-more-messages')}</Link>
    )}
    {Object.keys(unreadMessagesByThread).length === 0 && (
      <Link className="dropdown-item text-center small text-gray-500" to="/messages">{t('goto-messages')}</Link>
    )}
  </div>
</li>


        <div className="topbar-divider d-none d-sm-block"></div>

        <li className={`nav-item dropdown no-arrow ${openDropdown === 'user' ? 'show' : ''}`}>
          <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" onClick={() => toggleDropdown('user')} aria-haspopup="true" aria-expanded={openDropdown === 'user'}>
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{user.preferredName || user.fullName}</span>
            <img className="img-profile rounded-circle" src={user.profilePicture || './profile.png'} alt="User Profile" />
          </a>
          <div className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${openDropdown === 'user' ? 'show' : ''}`} aria-labelledby="userDropdown">
            <a className="dropdown-item" href="#" onClick={() => handleMenuItemClick('profile')}>
              <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2 text-gray-400" />
              {t('profile')}
            </a>
            <a className="dropdown-item" href="#" onClick={() => handleMenuItemClick('settings')}>
              <FontAwesomeIcon icon={faCogs} className="fa-sm fa-fw mr-2 text-gray-400" />
              {t('settings')}
            </a>
            <a className="dropdown-item" href="#" onClick={() => handleMenuItemClick('activity-log')}>
              <FontAwesomeIcon icon={faList} className="fa-sm fa-fw mr-2 text-gray-400" />
              {t('activity-log')}
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#" onClick={handleLogout} data-toggle="modal" data-target="#logoutModal">
              <FontAwesomeIcon icon={faSignOutAlt} className="fa-sm fa-fw mr-2 text-gray-400" />
              {t('logout')}
            </a>
          </div>
        </li>
      </ul>

      {isProfileModalOpen && (
        <Modal onClose={handleCloseProfileModal} title={t('userProfile')} modalWidth='860px' modalHeight='770px'>
          <UserProfile />
        </Modal>
      )}

      {isSettingsModalOpen && (
        <Modal onClose={handleCloseSettingsModal} title={t('settings')} modalWidth='860px' modalHeight='770px'>
          <Settings />
        </Modal>
      )}

      {isActivityLogModalOpen && (
        <Modal onClose={handleCloseActivityLogModal} title={t('activityLog')} modalWidth='860px' modalHeight='770px'>
          <ActivityLog />
        </Modal>
      )}
    </nav>
  );
};

export default Header;
