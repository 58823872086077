import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './CustomToolbar.module.css'; // Importing the CSS module

const CustomToolbar: React.FC<ToolbarProps> = ({ localizer, label, onNavigate, view, onView }) => {
  return (
    <div className={styles.customToolbar}>
      <div className={styles.toolbarLeft}>
        <div className={styles.viewButtons}>
          <button
            className={view === 'month' ? styles.active : ''}
            onClick={() => onView('month')}
          >
            Month
          </button>
          <button
            className={view === 'week' ? styles.active : ''}
            onClick={() => onView('week')}
          >
            Week
          </button>
          <button
            className={view === 'day' ? styles.active : ''}
            onClick={() => onView('day')}
          >
            Day
          </button>
        </div>
        <span className={styles.toolbarLabel}>{label}</span>
      </div>
      <div className={styles.toolbarRight}>
      <div className={styles.viewButtons}>&nbsp;</div>
        <button
          className={styles.customPrevBtn}
          onClick={() => onNavigate('PREV')}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className={styles.customTodayBtn}
          onClick={() => onNavigate('TODAY')}
        >
          <FontAwesomeIcon icon={faDotCircle} />
        </button>
        <button
          className={styles.customNextBtn}
          onClick={() => onNavigate('NEXT')}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
