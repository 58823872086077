import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AnnualLeaveStats, RequestAndTask, DaysSinceLastHoliday, DashboardState } from '../types/types';

// Conditional import based on environment
const useMockApi = process.env.REACT_APP_USE_MOCK_API !== 'true';
const api = useMockApi ? require('../api/dashboardMockApi') : require('../api/dashboardApi');

export const fetchDashboardData = createAsyncThunk('dashboard/fetchDashboardData', async (token: string) => {
  const annualLeaveStats: AnnualLeaveStats = await api.fetchAnnualLeaveStats(token);
  const requestsAndTasks: RequestAndTask[] = await api.fetchRequestsAndTasks(token);
  const daysSinceLastHoliday: DaysSinceLastHoliday[] = await api.fetchDaysSinceLastHoliday(token);
  return { annualLeaveStats, requestsAndTasks, daysSinceLastHoliday };
});

const initialState: DashboardState = {
  annualLeaveStats: null,
  requestsAndTasks: [],
  daysSinceLastHoliday: [],
  status: 'idle',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.annualLeaveStats = action.payload.annualLeaveStats;
        state.requestsAndTasks = action.payload.requestsAndTasks;
        state.daysSinceLastHoliday = action.payload.daysSinceLastHoliday;
        state.status = 'succeeded';
      })
      .addCase(fetchDashboardData.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default dashboardSlice.reducer;
