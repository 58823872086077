// src/slices/absencesSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { Absence, initialState, NonWorkingDay } from '../types/types';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/absenceMockApi') : require('../api/absenceApi');

// Fetch Absences
export const fetchAbsences = createAsyncThunk(
    'absences/fetchAbsences',
    async () => {
      const response = await api.fetchAbsencesApi('token', '1');
      return response.data;
    }
  );
  
// Book Absence
export const bookAbsence = createAsyncThunk(
    'absences/bookAbsence',
    async (newAbsence: Absence) => {
      const response = await api.bookAbsenceApi('token',newAbsence);
      return response.data;
    }
  );
  
// Fetch Non-Working Days (previously Public Holidays)
export const fetchNonWorkingDays = createAsyncThunk(
    'absences/fetchNonWorkingDays',
    async () => {
      const response = await api.fetchNonWorkingDaysApi('token'); // Adjust this API call
      return response.data;
    }
  );

const absencesSlice = createSlice({
  name: 'absences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbsences.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAbsences.fulfilled, (state, action: PayloadAction<Absence[]>) => {
        state.absences = action.payload;
        state.status = 'succeeded';
        state.loading = false;
      })
      .addCase(fetchAbsences.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch absences';
      })
      .addCase(bookAbsence.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(bookAbsence.fulfilled, (state, action: PayloadAction<Absence>) => {
        state.absences = [...state.absences, action.payload]; // Create a new array with the new absence
        state.status = 'succeeded';
        state.loading = false;
      })
      .addCase(bookAbsence.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to book absence';
      })
      .addCase(fetchNonWorkingDays.fulfilled, (state, action: PayloadAction<NonWorkingDay[]>) => {
        state.nonWorkingDays = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchNonWorkingDays.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch non working days';
      })
      .addCase(fetchNonWorkingDays.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      });
      
  },
});

export default absencesSlice.reducer;
