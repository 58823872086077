import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { sendMessageThunk, updateMessageStatus } from '../slices/messagesSlice';
import { Thread, Message, User } from '../types/types';
import { useTranslation } from 'react-i18next';
import styles from './Messages.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faImage, faCogs, faQuestion, faPaperPlane, faFileAlt } from '@fortawesome/free-solid-svg-icons';

interface MessageThreadProps {
  thread: Thread;
  currentUser: User;
}

const MessageThread: React.FC<MessageThreadProps> = ({ thread, currentUser }) => {
  const { t } = useTranslation('messages');
  const [newMessageContent, setNewMessageContent] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const dispatch = useAppDispatch();

  // Mark messages as read when the thread is opened
  useEffect(() => {
    thread.messages.forEach((message) => {
      if (!message.read && message.sender.id !== currentUser.id) {
        dispatch(updateMessageStatus({ messageId: message.id, threadId: thread.id, read: true }));
      }
    });
  }, [thread, dispatch, currentUser.id]);

  const handleSendMessage = () => {
    const newMessage: Message = {
      id: `msg${Date.now()}`,
      threadId: thread.id,
      sender: currentUser,
      content: newMessageContent,
      timestamp: new Date().toISOString(),
      attachments: attachments.map((file, index) => ({
        id: `att${Date.now()}_${index}`,
        fileName: file.name,
        fileType: file.type,
        url: URL.createObjectURL(file),
      })),
      mentions: extractMentions(newMessageContent),
      read: true, // Mark new message as read since the sender is the current user
    };

    dispatch(sendMessageThunk(newMessage));
    setNewMessageContent('');
    setAttachments([]);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAttachments([...attachments, ...Array.from(e.target.files)]);
    }
  };

  const extractMentions = (content: string): string[] => {
    const mentionPattern = /@(\w+)/g;
    const matches = [...content.matchAll(mentionPattern)];
    return matches.map(match => match[1]);
  };

  const getFirstName = (fullName: string): string => {
    return fullName.split(' ')[0];
  };

  return (
    <div className={styles.chat}>
      <div className={styles.chatHeader}>
        <div className="row">
          <div className="col-lg-6">
            <img src={thread.participants[0].profilePicture || 'https://bootdey.com/img/Content/avatar/avatar1.png'} alt="avatar" />
            <div className={styles.chatAbout}>
              <h6 className="m-b-0">
                {thread.participants.map(participant => participant.name).join(', ')}
              </h6>
              <small>{t('last-seen')}: {new Date(thread.lastUpdated).toLocaleTimeString()}</small>
            </div>
          </div>
          <div className="col-lg-6 text-right">
            {/* <button className="btn btn-outline-secondary"><FontAwesomeIcon icon={faCamera} /></button>
            <button className="btn btn-outline-primary"><FontAwesomeIcon icon={faImage} /></button>
            <button className="btn btn-outline-info"><FontAwesomeIcon icon={faCogs} /></button>
            <button className="btn btn-outline-warning"><FontAwesomeIcon icon={faQuestion} /></button> */}
          </div>
        </div>
      </div>

      <div className={styles.chatHistory}>
        <ul className="m-b-0">
          {thread.messages.map((message) => {
            const isCurrentUser = message.sender.id === currentUser?.id;
            const firstName = getFirstName(message.sender.name);

            return (
              <li key={message.id} className={`${styles.clearfix} ${isCurrentUser ? styles.myMessageContainer : styles.otherMessageContainer}`}>
                {!isCurrentUser && (
                  <div className={styles.messageDataLeft}>
                    <img className={styles.profilePicture} src={message.sender.profilePicture || 'https://bootdey.com/img/Content/avatar/avatar2.png'} alt="avatar" />
                    <span className={styles.messageDataTime}>{new Date(message.timestamp).toLocaleTimeString()}</span>
                  </div>
                )}
                <div className={`${styles.message} ${isCurrentUser ? styles.myMessage : styles.otherMessage}`}>
                  <div className={styles.messageHeader}>
                    <span className={styles.senderName} data-tooltip-id={`tooltip-${message.id}`} data-tooltip-content={message.sender.name}>
                      {firstName}
                    </span>
                    <div className={styles.tooltip} id={`tooltip-${message.id}`}>
                      <img src={message.sender.profilePicture || 'https://bootdey.com/img/Content/avatar/avatar2.png'} alt="avatar" className={styles.tooltipProfilePicture} />
                      <div className={styles.tooltipContent}>
                        <p><strong>{message.sender.name}</strong></p>
                        <p>{t('email')}: {message.sender.name || t('email-not-available')}</p>
                        <p>{t('status')}: {message.sender.isOnline ? t('online') : t('offline')}</p>
                      </div>
                    </div>
                  </div>
                  {message.content}
                  {message.attachments && message.attachments.length > 0 && (
                    <div className={styles.attachments}>
                      {message.attachments.map((attachment) => (
                        <a key={attachment.id} href={attachment.url} download={attachment.fileName} className={styles.attachmentLink}>
                          <FontAwesomeIcon icon={faFileAlt} /> {attachment.fileName}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
                {isCurrentUser && (
                  <div className={styles.messageDataRight}>
                    <img className={styles.profilePicture} src={message.sender.profilePicture || 'https://bootdey.com/img/Content/avatar/avatar1.png'} alt="avatar" />
                    <span className={styles.messageDataTime}>{new Date(message.timestamp).toLocaleTimeString()}</span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div className={styles.chatMessage}>
        <div className="input-group mb-0">
          <div className="input-group-prepend">
            <span className="input-group-text"><FontAwesomeIcon icon={faPaperPlane} /></span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={t('enter-text-here')}
            value={newMessageContent}
            onChange={(e) => setNewMessageContent(e.target.value)}
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <div className="input-group-append">
            <button className="btn btn-primary" onClick={handleSendMessage}>
              {t('send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageThread;
