import React, { useState } from 'react';
import Modal from '../../../components/Common/Modal';
import NotFound from '../../../components/Common/NotFound';
import styles from './UserProfile.module.css'; // Assume styles are defined in a CSS module
import EditPersonalDetails from './EmployeeDetails/EditEmployeeDetails';
import EditContactDetails from './ContactDetails/EditContactDetails';
import Tasks from './Tasks/Tasks';
import Calendar from './Calendar/Calendar';
import Files from './Files/Files';
import EmployeeDetails from './EmployeeDetails/EmployeeDetails';
import ContactInfo from './ContactDetails/ContactDetails';
import Overview from './Overview/Overview'; // Import the new OverviewView component
import { selectCurrentUserProfile, updateUserProfile } from '../slices/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import EditOverview from './Overview/EditOverview';
import ProfilePicture from '../../../components/Common/ProfilePicture';

const UserProfile: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();

  const userProfile = useSelector((state: RootState) => selectCurrentUserProfile(state));

  const [editingSection, setEditingSection] = useState<string | null>(null);
  const [viewingSection, setViewingSection] = useState<string | null>('overview');

  const handleSaveAndExit = async () => {
    setEditingSection(null);
  };

  const handleProfilePicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newProfilePicture = reader.result as string;
        if (userProfile) {
          // Dispatch action to update the profile picture
          dispatch(updateUserProfile({ ...userProfile, profilePicture: newProfilePicture }));
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const renderEditModal = () => {
    switch (editingSection) {
      case 'overview':
        return (
          <Modal onClose={() => setEditingSection(null)} title="Edit Overview">
            <EditOverview profile={userProfile!} onClose={handleSaveAndExit} />
          </Modal>
        );
      case 'employeeDetails':
        return (
          <Modal onClose={() => setEditingSection(null)} title="Edit Personal Details">
            <EditPersonalDetails onClose={handleSaveAndExit} />
          </Modal>
        );
      case 'contactInfo':
        return (
          <Modal onClose={() => setEditingSection(null)} title="Edit Contact Info">
            <EditContactDetails onClose={handleSaveAndExit} />
          </Modal>
        );
      default:
        return null;
    }
  };

  const renderMainContent = () => {
    switch (viewingSection) {
      case 'overview':
        return (
          <Overview
            profile={userProfile!}
            onEdit={() => setEditingSection('overview')}
          />
        );
      case 'employeeDetails':
        return (
          <EmployeeDetails
            fullName={userProfile?.fullName || ''}
            preferredName={userProfile?.preferredName || ''}
            professionalSuffix={userProfile?.professionalSuffix || ''}
            description={userProfile?.description || ''}
            department={userProfile?.department || ''}
            jobTitle={userProfile?.jobTitle || ''}
            hireDate={userProfile?.hireDate || ''}
            manager={userProfile?.manager || ''}
            location={userProfile?.location || ''}
            onEdit={() => setEditingSection('employeeDetails')}
          />
        );
      case 'contactInfo':
        return (
          <ContactInfo
            email={userProfile?.contactDetails?.email || ''}
            phone={userProfile?.contactDetails?.phone || ''}
            onEdit={() => setEditingSection('contactInfo')}
          />
        );
      case 'tasks':
        return <Tasks />;
      case 'calendar':
        return <Calendar />;
      case 'files':
        return <Files />;
      default:
        return null;
    }
  };

  // Utility function to determine if the profilePicture is base64 or URL
  const getProfilePictureSrc = () => {
    if (!userProfile || !userProfile.profilePicture) return ''; // Return empty string if userProfile or profilePicture is null
    const { profilePicture } = userProfile;
    return profilePicture.startsWith('data:image') || profilePicture.startsWith('data:application') || profilePicture.startsWith('data:') 
      ? profilePicture 
      : profilePicture; // Return the URL directly if it's not base64
  };

  if (!userProfile) {
    return <NotFound placeholder="User Profile" />;
  }

  return (
    <div className={styles.userProfileContainer}>
      <div className={styles.sidebar}>
      <div className={styles.profileHeader}>
          <ProfilePicture
            src={getProfilePictureSrc()}
            alt={userProfile.fullName}
            onChange={handleProfilePicChange}
          />
          <h2>{userProfile.fullName}</h2>
          <p>{userProfile.jobTitle}</p>
        </div>
        <div className={styles.profileNav}>
          <a href="#" onClick={() => setViewingSection('overview')}>Overview</a>
          <a href="#" onClick={() => setViewingSection('employeeDetails')}>Employee Details</a>
          <a href="#" onClick={() => setViewingSection('contactInfo')}>Contact Info</a>
          <a href="#" onClick={() => setViewingSection('tasks')}>Tasks</a>
          <a href="#" onClick={() => setViewingSection('calendar')}>Calendar</a>
          <a href="#" onClick={() => setViewingSection('files')}>Files</a>
        </div>
      </div>

      <div className={styles.mainContent}>
        {renderMainContent()}
      </div>

      {renderEditModal()}
    </div>
  );
};

export default UserProfile;
