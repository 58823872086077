// employee/components/WorkExperience.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkExperienceHistory as WorkExperienceHistoryType } from '../../types/types';
import styles from '../EmployeeProfile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface WorkExperienceHistoryProps {
  workExperienceHistory: WorkExperienceHistoryType[];
  onEdit: () => void;
}

const WorkExperienceHistory: React.FC<WorkExperienceHistoryProps> = ({ workExperienceHistory, onEdit }) => {
  const { t } = useTranslation('employee');
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = () => {
    setOpenDropdown(null);
    onEdit();
  };

  return (
    <div className="col-xl col-lg">
      <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h2>{t('work_experience_history_component.title')}</h2>
            <div className="dropdown no-arrow">
              <a
                className="dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink1"
                onClick={() => toggleDropdown('dropdown1')}
                aria-haspopup="true"
                aria-expanded={openDropdown === 'dropdown1' ? 'true' : 'false'}
              >
                <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
              </a>
              {openDropdown === 'dropdown1' && (
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                  aria-labelledby="dropdownMenuLink1"
                  onClick={handleMenuItemClick}
                >
                  <div className="dropdown-item">
                    {t('work_experience_history_component.edit')}
                  </div>
                </div>
              )}
            </div>
      </div>
      <div className='card-body'>

        <div className={styles.tab_content}>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>{t('work_experience_history_component.employer')}</th>
                <th>{t('work_experience_history_component.job_title')}</th>
                <th>{t('work_experience_history_component.start_date')}</th>
                <th>{t('work_experience_history_component.end_date')}</th>
              </tr>
            </thead>
            <tbody>
            {workExperienceHistory?.map((experience, index) => (
          <tr key={index} className={styles.work_experience_row}>
            <td>{experience.employer}</td>
            <td>{experience.jobTitle}</td>
            <td>{new Date(experience.startDate).toDateString()}</td>
            <td>{experience.endDate ? new Date(experience.endDate).toDateString() : t('work_experience_history_component.present')}</td>
          </tr>
        ))}
      </tbody>
    </table>
          </div>

        </div>
        </div>
      </div>
  );
};

export default WorkExperienceHistory;
