import { MenuItem } from './types/types';
import MenuList from './components/MenuList';

interface SidebarProps {
  routes: MenuItem[];
  isPinned: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ routes, isPinned }) => {
  
  return (
    <MenuList menus={routes} />
  );
};

export default Sidebar;
