import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

interface ErrorProps {
  error: string;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
  return (
    <div className="content-body">
      <p>An error occurred: {error}</p>
      <Link to="/" className="go-home-button">
        Go Home
      </Link>
    </div>
  );
};

export default Error;
