import { Message, Thread, User } from '../types/types';

export const fetchMessages = async (): Promise<Thread[]> => {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 1000));

  const mockUser1: User = { id: '1', name: 'mock1', isOnline: true };  
  const mockUser2: User = { id: '2', name: 'mock2', isOnline: true };  

  const mockUser3: User = { id: '3', name: 'mock3', isOnline: false };  
  const mockUser4: User = { id: '4', name: 'mock4', isOnline: true };  

  // Mock data
  const mockThreads: Thread[] = [
    {
      id: 'thread1',
      subject: 'Welcome newbie',
      participants: [mockUser1, mockUser2],
      lastUpdated: new Date().toISOString(),
      messages: [
        {
          id: 'msg1',
          threadId: 'thread1',
          sender: mockUser1,
          content: 'Hey, how are you?',
          timestamp: new Date().toISOString(),
          mentions: ['user2'],
          attachments: [],
          read: true
        },
        {
          id: 'msg2',
          threadId: 'thread1',
          sender: mockUser2,
          content: 'I am good, thanks!',
          timestamp: new Date().toISOString(),
          mentions: [],
          attachments: [],
          read: false
        },
      ],
    },
    {
      id: 'thread2',
      subject: 'ACW Pitch',
      participants: [mockUser1, mockUser3, mockUser4],
      lastUpdated: new Date().toISOString(),
      messages: [
        {
          id: 'msg3',
          threadId: 'thread2',
          sender: mockUser3,
          content: 'Are we still on for tomorrow?',
          timestamp: new Date().toISOString(),
          mentions: ['user4'],
          attachments: [
            {
              id: 'attach1',
              fileName: 'agenda.pdf',
              fileType: 'application/pdf',
              url: 'http://example.com/agenda.pdf',
            },
          ],
          read: true
        },
        {
            id: 'msg4',
            threadId: 'thread2',
            sender: mockUser4,
            content: 'Yes, looking forward to it!',
            timestamp: new Date().toISOString(),
            mentions: [],
            attachments: [],
            read: true
          },
        {
          id: 'msg5',
          threadId: 'thread2',
          sender: mockUser1,
          content: 'Of course',
          timestamp: new Date().toISOString(),
          mentions: [],
          attachments: [],
          read: true
        },
        
      ],
    },
  ];

  return mockThreads;
};

export const sendMessage = async (message: Message): Promise<Message> => {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 500));

  // Normally, you'd send this message to the backend here.
  // For the mock, we just return it.
  return {
    ...message,
    id: `msg${Date.now()}`,  // Simulate the backend generating a unique ID
  };
};
