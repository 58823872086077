import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { togglePinned } from '../../../slices/navbarSlice';
import { MenuItem } from "../types/types";
import MenuItemComponent from "./MenuItemComponent";
import "./MenuList.css";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import logo from '../../hrh-icon-white.png';

export type MenuListProps = {
  menus: MenuItem[];
};

const MenuList = ({ menus }: MenuListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isPinned = useSelector((state: RootState) => state.rootReducer.navbar.isPinned);
  
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const menuRef = useRef<HTMLUListElement>(null); // Ref to the menu list

  const handleToggle = (menuLabel: string) => {
    if (isPinned) {
      setOpenMenu(menuLabel);
    } else {
      setOpenMenu(openMenu === menuLabel ? null : menuLabel);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node) && !isPinned) {
      setOpenMenu(null); // Close the menu when clicking outside only if the menu is not pinned (not expanded)
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPinned]); // Re-run the effect when `isPinned` changes

  const generateRandomId = (): string => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <ul
      className={isPinned ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion sidebar-pinned" : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"}
      id="accordionSidebar"
      ref={menuRef} // Attach ref to the menu list
    >
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-icon">
          <img src={logo} alt="HR Harbour Icon" style={{ maxWidth: "60%", maxHeight: "auto", marginLeft: "auto", marginRight: "auto" }} />
        </div>
        <div className="sidebar-brand-text mx-3">HRH</div>
      </a>

      <hr className="sidebar-divider my-0" />

      {menus.map((menu) => (
        <MenuItemComponent
          key={generateRandomId()}
          menu={menu}
          isOpen={openMenu === menu.label}
          onToggle={() => handleToggle(menu.label || '')}
        />
      ))}

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" style={{ color: "white" }} id="sidebarToggle" onClick={() => dispatch(togglePinned())}><FontAwesomeIcon icon={faMapPin} /></button>
      </div>
    </ul>
  );
};

export default MenuList;
