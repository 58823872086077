// src/components/TeamCalendarView.tsx
import React from 'react';
import CalendarView from './CalendarView';

const TeamCalendarView: React.FC = () => {
  return (
    <div>
      <CalendarView teamView={true} />
    </div>
  );
};

export default TeamCalendarView;
