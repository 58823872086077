import React, { useState } from 'react';

interface SaveButtonWithDropdownProps {
  onSave: (e: React.FormEvent, continueEditing?: boolean, setDropdownOpen?: (open: boolean) => void) => void;
  loading: boolean;
  saveLabel: string;
  saveAndContinueLabel: string;
}

const SaveButtonWithDropdown: React.FC<SaveButtonWithDropdownProps> = ({
  onSave,
  loading,
  saveLabel,
  saveAndContinueLabel,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="btn-group">
      <button className="btn btn-success" type="submit" onClick={(e) => onSave(e, false, setDropdownOpen)} disabled={loading}>
        {saveLabel}
      </button>
      <button
        className="btn btn-success dropdown-toggle dropdown-toggle-split"
        type="button"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
      </button>
      <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
        <button className="dropdown-item" type="button" onClick={(e) => onSave(e, true, setDropdownOpen)}>
          {saveAndContinueLabel}
        </button>
      </div>
    </div>
  );
};

export default SaveButtonWithDropdown;
