import { EmployeeProfile } from "../types/types";
import { getAppConfig } from '../../../config/configManager'

import axiosRetry from 'axios-retry';
import axios from "axios";

// Retry failed requests up to 3 times
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const API_BASE_URL = getAppConfig().baseUrl;

const fetchEmployeeProfile = async (token: string, id: string): Promise<EmployeeProfile> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/employee/${id}/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Axios automatically parses the response as JSON
    return response.data as EmployeeProfile;
  } catch (error) {
    throw new Error('Failed to fetch employee profile');
  }
};

const updateEmployeeProfile = async (token: string, newProfile: EmployeeProfile) => {
  try {
    await axios.put(`${API_BASE_URL}/employee/${newProfile.id}/profile`, newProfile, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  } catch (error) {
    throw new Error('Failed to update employee profile');
  }
};

export { fetchEmployeeProfile, updateEmployeeProfile };
