import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchDashboardData } from '../slices/dashboardSlice';
import { RootState, AppDispatch } from '../../../store/store';

export const useDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { annualLeaveStats, requestsAndTasks, daysSinceLastHoliday, status } = useSelector((state: RootState) => state.dashboard);
  const user = useSelector((state: RootState) => state.rootReducer.auth?.user);

  useEffect(() => {
    if (status === 'failed')
      {
        throw new Error('Oops, something went wrong!');
      }
    if (status === 'idle' && user !== null) {
      dispatch(fetchDashboardData(user.access_token));
    }
  }, [status, dispatch]);

  return { annualLeaveStats, requestsAndTasks, daysSinceLastHoliday, status };
};