import React, { useState, useEffect } from 'react';
import i18n from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const LanguageSelectorComponent: React.FC = () => {
  const { t } = useTranslation();

  const [languages] = useState([
    'en-GB',
    'ES',
    'CS',
    'DA',
    'DE',
    'ET',
    'FR',
    'IT',
    'JA',
    'KO',
    'LT',
    'NL',
    'PL',
    'RO',
    'SV',
    'UK'
  ]);

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  useEffect(() => {
    // Set default values
    const defaultLanguage = languages.length > 0 ? languages[0] : '';
    const storedLanguage = localStorage.getItem('selectedLanguage');
    setSelectedLanguage(storedLanguage || defaultLanguage);
  }, [languages]);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  if (languages.length > 1) {
    return (
      <div title={t('language-selector-title', { selectedLanguage })}>
        <form
          id="SelectLanguage"
          onSubmit={(e) => e.preventDefault()}
          className="form-inline justify-content-end"
          role="form"
        >
          <div className="form-group">
            <label htmlFor="selectedLanguage" className="mr-2">
              {t('language-label')}
            </label>
            <select
              id="selectedLanguage"
              name="language"
              onChange={handleLanguageChange}
              value={selectedLanguage}
              className="form-control"
            >
              {languages.map((language) => (
                <option key={language} value={language}>
                  {t(`language-${language}`)}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
    );
  }

  // If there's only one language, or no languages, you might want to handle that case accordingly
  return <div></div>;
};

export default LanguageSelectorComponent;
