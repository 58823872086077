import React from 'react';
import { Wizard } from 'react-use-wizard';
import StepWrapper from './StepWrapper';
import EditPersonalDetails from '../EditPersonalDetails';
import EditAddresses from '../EditAddresses';
import EditWorkExperience from '../WorkExperience/EditWorkExperience';
import Header from './Header';
import Footer from './Footer';
import { useEmployeeData } from '../../hooks/useEmployeeData';
import { v4 as uuidv4 } from 'uuid';


const stepTitles = [
  'Personal Details',
  'Addresses',
  'Work Experience'
];

const OnboardingWizard: React.FC = () => {

  const { employeeProfile, status, error } = useEmployeeData(uuidv4());

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', paddingLeft: '100px', paddingRight: '100px' }}>
      <Wizard
        startIndex={0}
        header={<Header stepTitles={stepTitles} />}
        footer={<Footer />}
      >
        <StepWrapper>
          <EditPersonalDetails onClose={() => {}} />
        </StepWrapper>
        <StepWrapper>
          <EditAddresses onClose={() => {}} />
        </StepWrapper>
        <StepWrapper>
          <EditWorkExperience onClose={() => {}} />
        </StepWrapper>
      </Wizard>
    </div>
  );
};

export default OnboardingWizard;


