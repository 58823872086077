// src/hooks/useAbsenceData.ts
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbsences, fetchNonWorkingDays } from '../slices/absencesSlice';
import { AppDispatch, RootState } from '../../../store/store';

export const useAbsenceData = () => {
  const dispatch = useDispatch<AppDispatch>();

  const absences = useSelector((state: RootState) => state.absences.absences);
  const nonWorkingDays = useSelector((state: RootState) => state.absences.nonWorkingDays);
  const loading = useSelector((state: RootState) => state.absences.loading);
  const error = useSelector((state: RootState) => state.absences.error);
  const status = useSelector((state: RootState) => state.absences.status);

  useEffect(() => {
    if (status === 'failed') {
      throw new Error('Oops, something went wrong!');
    }
    if (absences.length === 0 && !loading) {
      dispatch(fetchAbsences());
    }
    if (nonWorkingDays.length === 0 && !loading) {
      dispatch(fetchNonWorkingDays());
    }
  }, [dispatch, absences.length, nonWorkingDays.length, loading, status]);

  return { absences, nonWorkingDays, loading, error };
};
