import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEmployeeData } from '../hooks/useEmployeeData';
import styles from './EmployeeProfile.module.css';
import Dependents from './Dependents/Dependents';
import EditDependents from './Dependents/EditDependents';
import EducationHistory from './EducationHistory/EducationHistory';
import EditEducationHistory from './EducationHistory/EditEducationHistory';
import WorkExperience from './WorkExperience/WorkExperience';
import EditWorkExperience from './WorkExperience/EditWorkExperience';
import SalaryHistory from './SalaryHistory/SalaryHistory';
import EditSalaryHistory from './SalaryHistory/EditSalaryHistory';
import EditContactInfo from './EditContactInfo';
import ContactInfo from './ContactInfo';
import Modal from '../../../components/Common/Modal';
import EditPersonalDetails from './EditPersonalDetails';
import EditAddresses from './EditAddresses';
import EditEmergencyContacts from './EditEmergencyContacts';
import EditCustomFields from './EditCustomFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { selectEmergencyContacts, updateEmployeeProfile } from '../slices/employeeDetailsSlice';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useParams } from 'react-router-dom';
import NotFound from '../../../components/Common/NotFound';
import Loading from '../../../components/Common/Loading';
import Error from '../../../components/Common/Error';
import ProfilePicture from '../../../components/Common/ProfilePicture';


export const EmployeeProfile: React.FC = () => {
  const { t } = useTranslation('employee');
  const { employeeId } = useParams<{ employeeId: string }>(); // Extract employeeId from URL
  const dispatch: AppDispatch = useDispatch();
  //TODO if none selected do we default to the user logged in?

  const { employeeProfile, status, error } = useEmployeeData(employeeId || '');

  const emergencyContacts = useSelector((state: RootState) => selectEmergencyContacts(state));
  
  const [activeTab, setActiveTab] = useState('summary');
  const [editingSection, setEditingSection] = useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [newProfilePic, setNewProfilePic] = useState<string | null>(employeeProfile?.profilePicture || '');

  const toggleDropdown = (key: string, event: React.MouseEvent) => {
    event.preventDefault();
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = (section: string) => {
    setOpenDropdown(null);
    setEditingSection(section);
  };

  const handleSaveAndExit = async () => {
    await new Promise(res => setTimeout(res, 250));
    setEditingSection(null);
  };

  const handleProfilePicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProfilePic(reader.result as string);
        // Dispatch action to update profile picture
        if (employeeProfile) {
          dispatch(updateEmployeeProfile({ ...employeeProfile, profilePicture: reader.result as string }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  if (status === 'failed') {
    return <Error error={error || 'Unknown error'} />;
  }

  if (status === 'succeeded' && !employeeProfile) {
    return <NotFound placeholder='Employee Profile' />;
  }

  const renderEditModal = () => {
    switch (editingSection) {
      case 'contactInfo':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('contact_details')}`} modalHeight='720px'><EditContactInfo onClose={() => handleSaveAndExit()} /></Modal>;
      case 'dependents':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('dependents')}`} modalHeight='720px'><EditDependents onClose={() => handleSaveAndExit()} /></Modal>;
      case 'educationHistory':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('education_history')}`} modalHeight='720px'><EditEducationHistory onClose={() => handleSaveAndExit()} /></Modal>;
      case 'salaryHistory':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('salary_history')}`} modalHeight='720px'><EditSalaryHistory onClose={() => handleSaveAndExit()} /></Modal>;
      case 'workExperience':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('work_experience_history')}`} modalHeight='720px'><EditWorkExperience onClose={() => handleSaveAndExit()} /></Modal>;
      case 'personalDetails':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('personal_details')}`} modalHeight='720px'><EditPersonalDetails onClose={() => handleSaveAndExit()} /></Modal>;
      case 'addresses':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('addresses')}`} modalHeight='720px'><EditAddresses onClose={() => handleSaveAndExit()} /></Modal>;
      case 'emergencyContacts':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('emergency_contacts')}`} modalHeight='720px'><EditEmergencyContacts onClose={() => handleSaveAndExit()} /></Modal>;
      case 'customFields':
        return <Modal onClose={() => setEditingSection(null)} title={`Edit ${t('custom_fields')}`} modalHeight='720px'><EditCustomFields onClose={() => handleSaveAndExit()} /></Modal>;
      default:
        return null;
    }
  };

  return (
    <Loading loading={(status === 'loading' || status === 'idle')}>
      <div className='row'>
        <div className={styles.employee_profile}>
          <div className={styles.header}>
          <div className={styles.profile_info}>
            <ProfilePicture 
              src={newProfilePic || employeeProfile?.profilePicture}
              alt="Profile"
              onChange={handleProfilePicChange}
            />
            <div>
              <h1>{employeeProfile?.fullName}</h1>
              <p>{employeeProfile?.jobTitle}</p>
              <p>{t('department')}: {employeeProfile?.department}</p>
            </div>
          </div>
            <div className={styles.employee_details}>
              <p><strong>{t('tax_id')}:</strong> {employeeProfile?.taxID}</p>
              <p><strong>{t('position_id')}:</strong> {employeeProfile?.jobTitle}</p>
              <p><strong>{t('hire_date')}:</strong> {new Date(employeeProfile?.hireDate).toDateString()}</p>
              <p><strong>{t('status')}:</strong> {t('active')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab_button} ${activeTab === 'summary' ? styles.tab_button_active : ''}`}
            onClick={() => setActiveTab('summary')}
          >
            {t('summary')}
          </button>
          <button
            className={`${styles.tab_button} ${activeTab === 'dependents' ? styles.tab_button_active : ''}`}
            onClick={() => setActiveTab('dependents')}
          >
            {t('dependents')}
          </button>
          <button
            className={`${styles.tab_button} ${activeTab === 'educationHistory' ? styles.tab_button_active : ''}`}
            onClick={() => setActiveTab('educationHistory')}
          >
            {t('education_history')}
          </button>
          <button
            className={`${styles.tab_button} ${activeTab === 'workExperienceHistory' ? styles.tab_button_active : ''}`}
            onClick={() => setActiveTab('workExperienceHistory')}
          >
            {t('work_experience_history')}
          </button>
          <button
            className={`${styles.tab_button} ${activeTab === 'salaryHistory' ? styles.tab_button_active : ''}`}
            onClick={() => setActiveTab('salaryHistory')}
          >
            {t('salary_history')}
          </button>
        </div>

        <div className={styles.tab_content}>
          {activeTab === 'summary' && (
            <>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-xl-8 col-lg-7'>
                    <div className={`card shadow mb-4`}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('card_name')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink1"
                            onClick={(event) => toggleDropdown('dropdown1', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown1' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown1' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink1"
                              onClick={() => handleMenuItemClick('personalDetails')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('personalDetails')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <p><strong>{t('full_name')}</strong>: {employeeProfile?.fullName}</p>
                        <p><strong>{t('preferred_name')}</strong>: {employeeProfile?.fullName}</p>
                        <p><strong>{t('professional_suffix')}</strong>: {employeeProfile?.professionalSuffix}</p>
                        <p><strong>{t('payroll_name')}</strong>: {employeeProfile?.fullName}</p>
                      </div>
                    </div>

                    <div className={`card shadow mb-4`}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('addresses')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink2"
                            onClick={(event) => toggleDropdown('dropdown2', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown2' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown2' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink2"
                              onClick={() => handleMenuItemClick('addresses')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('addresses')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <p><strong>{t('primary_address')}:</strong></p>
                        <p>{employeeProfile?.primaryAddress}</p>
                        <p><strong>{t('secondary_address')}:</strong></p>
                        <p>{employeeProfile?.secondaryAddress}</p>
                        <p>{t('works_from_home')}: {t('no')}</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-3'>
                    <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('personal_details')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink3"
                            onClick={(event) => toggleDropdown('dropdown3', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown3' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown3' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink3"
                              onClick={() => handleMenuItemClick('personalDetails')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('personalDetails')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <p><strong>{t('birth_date')}:</strong> {new Date(employeeProfile?.dateOfBirth).toDateString()}</p>
                        <p><strong>{t('marital_status')}:</strong> {employeeProfile?.maritalStatus}</p>
                        <p><strong>{t('gender')}:</strong> {employeeProfile?.gender}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>

                  <div className='col-xl-4 col-lg-3'>
                    <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('contact_details')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink4"
                            onClick={(event) => toggleDropdown('dropdown4', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown4' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown4' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink4"
                              onClick={() => handleMenuItemClick('contactInfo')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('contactInfo')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <ContactInfo onEdit={() => setEditingSection('contactInfo')} />
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-3'>
                    <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('emergency_contacts')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink5"
                            onClick={(event) => toggleDropdown('dropdown5', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown5' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown5' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink5"
                              onClick={() => handleMenuItemClick('emergencyContacts')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('emergencyContacts')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        {emergencyContacts.map((contact, index) => (
                          <div key={index}>
                            <p><strong>{contact.name}</strong></p>
                            <p>{contact.relationship}</p>
                            <p>{contact.phoneNumber}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-lg-3'>
                    <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h2>{t('custom_fields')}</h2>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink6"
                            onClick={(event) => toggleDropdown('dropdown6', event)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === 'dropdown6' ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === 'dropdown6' && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby="dropdownMenuLink6"
                              onClick={() => handleMenuItemClick('customFields')}
                            >
                              <div className="dropdown-item" onClick={() => handleMenuItemClick('customFields')}>
                                {t('edit')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <p>{t('shoe_size')}: {employeeProfile?.shoeSize}</p>
                        <p>{t('t_shirt_size')}: {employeeProfile?.tShirtSize}</p>
                        <p>{t('uniform_size')}: {employeeProfile?.uniformSize}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {activeTab === 'dependents' && (
            <>
              <Dependents dependents={employeeProfile?.dependents} onEdit={() => setEditingSection('dependents')} />
            </>
          )}
          {activeTab === 'educationHistory' && (
            <>
              <EducationHistory educationHistory={employeeProfile?.educationHistory} onEdit={() => setEditingSection('educationHistory')} />
            </>
          )}
          {activeTab === 'workExperienceHistory' && (
            <>
              <WorkExperience workExperienceHistory={employeeProfile?.workExperienceHistory} onEdit={() => setEditingSection('workExperience')} />
            </>
          )}
          {activeTab === 'salaryHistory' && (
            <>
              <SalaryHistory salaryHistory={employeeProfile?.salaryHistory} onEdit={() => setEditingSection('salaryHistory')} />
            </>
          )}
        </div>
        {renderEditModal()}
      </div>
    </Loading>
  );
};

export default EmployeeProfile;

