import axios from 'axios';
import { Absence } from '../types/types';
import { getAppConfig } from '../../../config/configManager';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const API_BASE_URL = getAppConfig().baseUrl;

// Helper function to check if the response is successful or throw an error
const handleResponse = (response: any) => {
  if (!response || response.status !== 200) {
    throw new Error(`Failed to fetch data. Status: ${response.status}`);
  }
  return response.data;
};

export const fetchAbsencesApi = async (token: string, employeeId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/${employeeId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error fetching absences for employee ${employeeId}:`, error);
    throw new Error('Failed to fetch absences');
  }
};

export const bookAbsenceApi = async (token: string, absence: Absence) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/holidays/${absence.employeeId}/book`, absence, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error booking absence for employee ${absence.employeeId}:`, error);
    throw new Error('Failed to book absence');
  }
};

export const fetchNonWorkingDaysApi = async (token: string, employeeId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/${employeeId}/nonworkingdays`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error fetching non-working days for employee ${employeeId}:`, error);
    throw new Error('Failed to fetch non-working days');
  }
};
