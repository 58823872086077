export const calculateTotalDays = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  };
  
  export const calculateAbsenceStatistics = (absences: any[], totalAllowance: number) => {
    const groupedAbsences = absences.reduce((acc: { [key: string]: number }, absence) => {
      const daysTaken = calculateTotalDays(absence.startDate, absence.endDate);
      if (acc[absence.type]) {
        acc[absence.type] += daysTaken;
      } else {
        acc[absence.type] = daysTaken;
      }
      return acc;
    }, {});
  
    const totalHolidaysTaken = groupedAbsences['holiday'] || 0;
    const remainingLeave = totalAllowance - totalHolidaysTaken;
  
    const totalDaysTaken = Object.values(groupedAbsences).reduce((total, days) => total + days, 0);
  
    return { groupedAbsences, totalHolidaysTaken, remainingLeave, totalDaysTaken };
  };
  