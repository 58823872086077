import devConfig, { devApiConfig } from './appConfig.dev';
import localConfig, { localApiConfig } from './appConfig.local';
import prodConfig, { prodApiConfig } from './appConfig.prod';

const getEnvironment = (): string => {
    const environment = process.env.NODE_ENV || 'development';
    // Default to 'development' if NODE_ENV is not set
  
    if (process.env.REACT_APP_IS_LOCAL === 'true')
    {
      console.log('Env var overriden, setting config to local.');
      return 'local';
    }

    switch (environment) {
      case 'development':
        return 'dev';
      case 'test':
        return 'test';
      case 'production':
        return 'prod';
      default:
        throw new Error(`Invalid environment: ${environment} for getEnvironment`);
    }
  };

export const getAuthConfig = () => {
  const environment = getEnvironment();
  console.log(environment);
  switch (environment) {
    case 'dev':
      return devConfig;
    case 'local':
      return localConfig;
    case 'prod':
      return prodConfig;
    default:
      throw new Error(`Invalid environment: ${environment} for getConfig`);
  }
};

export const getAppConfig = () => {
  const environment = getEnvironment();

  switch (environment) {
    case 'dev':
      return devApiConfig;
    case 'local':
      return localApiConfig;
    case 'prod':
      return prodApiConfig;
    default:
      throw new Error(`Invalid environment: ${environment} for getConfig`);
  }
};


