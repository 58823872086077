import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { AuthProvider } from 'react-oidc-context'; 
import { getAuthConfig } from './config/configManager';
import { BrowserRouter as Router } from 'react-router-dom';

import './i18n/i18n'; // Import i18n configuration (perhaps do this per feature so the language files stay concise)
import ErrorBoundary from './components/Common/ErrorBoundary';

const authConfig = getAuthConfig();

const oidcConfig = {
  authority: authConfig.authority,
  client_id: authConfig.client_id,
  redirect_uri: authConfig.redirect_uri,
  silent_redirect_uri: authConfig.silent_redirect_uri,
  automaticSilentRenew: authConfig.automaticSilentRenew
};
  
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Suspense>
      <Router>
        <React.StrictMode>
          <Provider store={store}>
            <AuthProvider {...oidcConfig}> 
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </AuthProvider>
          </Provider>
        </React.StrictMode>
      </Router>
    </Suspense>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

