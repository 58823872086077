import axios from 'axios';
import { getAppConfig } from '../../../config/configManager';
import axiosRetry from 'axios-retry';

// Retry failed requests up to 3 times
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const API_BASE_URL = getAppConfig().baseUrl;

const fetchAnnualLeaveStats = async (token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/Employee/ac2c576b-cbf3-42bd-b384-33bdf335d0ee/Statistics`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;  // Axios automatically parses the response as JSON
  } catch (error) {
    throw new Error('Failed to fetch annual leave stats');
  }
};

const fetchRequestsAndTasks = async (token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/Employee/ac2c576b-cbf3-42bd-b384-33bdf335d0ee`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch requests and tasks');
  }
};

const fetchDaysSinceLastHoliday = async (token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/Employee/ac2c576b-cbf3-42bd-b384-33bdf335d0ee/Statistics/DaysSinceLastHoliday`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch days since last holiday');
  }
};

export { fetchAnnualLeaveStats, fetchRequestsAndTasks, fetchDaysSinceLastHoliday };
