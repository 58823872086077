import React, { useState, useEffect } from 'react';
import { useAlertData } from '../hooks/useAlertData';
import Loading from '../../../components/Common/Loading';
import Error from '../../../components/Common/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Alert } from '../types/alertTypes';
import { format, isToday, isYesterday } from 'date-fns';
import { useAppDispatch } from '../../../app/hooks';
import { dismissAlert, pinAlert, unpinAlert } from '../slices/alertsSlice';

const groupAlertsByDate = (alerts: Alert[]) => {
  const grouped = {
    today: [] as Alert[],
    yesterday: [] as Alert[],
    older: [] as Alert[],
  };

  alerts.forEach((alert) => {
    const alertDate = new Date(alert.timestamp);

    if (isToday(alertDate)) {
      grouped.today.push(alert);
    } else if (isYesterday(alertDate)) {
      grouped.yesterday.push(alert);
    } else {
      grouped.older.push(alert);
    }
  });

  return grouped;
};

const AlertList: React.FC = () => {
  const { t } = useTranslation('alerts');
  const { alerts, status, error } = useAlertData();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDismiss = (id: string) => {
    dispatch(dismissAlert(id));
    setOpenDropdown(null);
  };

  const handlePinToggle = (id: string, isPinned: boolean) => {
    if (isPinned) {
      dispatch(unpinAlert(id));
    } else {
      dispatch(pinAlert(id));
    }
    setOpenDropdown(null);
  };

  const handleForward = (id: string) => {
    console.log(`Forwarding alert with id ${id}`);
    setOpenDropdown(null);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  if (status === 'failed') {
    return <Error error={error || 'Failed to load alerts'} />;
  }

  const { today, yesterday, older } = groupAlertsByDate(alerts);
  const pinnedAlerts = alerts.filter(alert => alert.pinned);
  const nonPinnedAlerts = alerts.filter(alert => !alert.pinned);

  return (
    <Loading loading={(status === 'loading' || status === 'idle')}>

    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">{t('alert-centre-title')}</h1>
        <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <FontAwesomeIcon icon={faEllipsisV} className="text-white-50" /> {t('manage-alerts')}
        </a>
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-8">
        <h3>{t('latest-alerts')}</h3>
          {today.length > 0 && (
            <>
              <h4>{t('todays-alerts')}</h4>
              <div className="mb-4">
                {nonPinnedAlerts.filter(alert => isToday(new Date(alert.timestamp))).map((alert: Alert) => (
                  <div className="mb-4" key={alert.id}>
                    <div className={`card border-left-primary shadow h-100 py-2`}>
                      <div className="card-body position-relative">
                        <div className="dropdown no-arrow position-absolute" style={{ top: '10px', right: '10px' }}>
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id={`dropdownMenuLink${alert.id}`}
                            onClick={() => toggleDropdown(alert.id)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === alert.id ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === alert.id && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby={`dropdownMenuLink${alert.id}`}
                            >
                              <a className="dropdown-item" href="#" onClick={() => handleDismiss(alert.id)}>
                                {t('dismiss')}
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handlePinToggle(alert.id, alert.pinned)}
                              >
                                {alert.pinned ? t('unpin') : t('pin')}
                              </a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#" onClick={() => handleForward(alert.id)}>
                                {t('forward')}
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              {alert.source}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{alert.title}</div>
                            <div className="text-xs text-gray-600">{new Date(alert.timestamp).toLocaleString()}</div>
                            <p className="mt-2 text-gray-800">{alert.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {yesterday.length > 0 && (
            <>
              <h4>{t('yesterdays-alerts')}</h4>
              <div className="mb-4">
                {nonPinnedAlerts.filter(alert => isYesterday(new Date(alert.timestamp))).map((alert: Alert) => (
                  <div className="mb-4" key={alert.id}>
                    <div className={`card border-left-primary shadow h-100 py-2`}>
                      <div className="card-body position-relative">
                        <div className="dropdown no-arrow position-absolute" style={{ top: '10px', right: '10px' }}>
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id={`dropdownMenuLink${alert.id}`}
                            onClick={() => toggleDropdown(alert.id)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === alert.id ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === alert.id && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby={`dropdownMenuLink${alert.id}`}
                            >
                              <a className="dropdown-item" href="#" onClick={() => handleDismiss(alert.id)}>
                                {t('dismiss')}
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handlePinToggle(alert.id, alert.pinned)}
                              >
                                {alert.pinned ? t('unpin') : t('pin')}
                              </a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#" onClick={() => handleForward(alert.id)}>
                                {t('forward')}
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              {alert.source}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{alert.title}</div>
                            <div className="text-xs text-gray-600">{new Date(alert.timestamp).toLocaleString()}</div>
                            <p className="mt-2 text-gray-800">{alert.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {older.length > 0 && (
            <>
              <h4>{t('older-alerts')}</h4>
              <div className="mb-4">
                {nonPinnedAlerts.filter(alert => !isToday(new Date(alert.timestamp)) && !isYesterday(new Date(alert.timestamp))).map((alert: Alert) => (
                  <div className="mb-4" key={alert.id}>
                    <div className={`card border-left-primary shadow h-100 py-2`}>
                      <div className="card-body position-relative">
                        <div className="dropdown no-arrow position-absolute" style={{ top: '10px', right: '10px' }}>
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id={`dropdownMenuLink${alert.id}`}
                            onClick={() => toggleDropdown(alert.id)}
                            aria-haspopup="true"
                            aria-expanded={openDropdown === alert.id ? 'true' : 'false'}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                          </a>
                          {openDropdown === alert.id && (
                            <div
                              className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                              aria-labelledby={`dropdownMenuLink${alert.id}`}
                            >
                              <a className="dropdown-item" href="#" onClick={() => handleDismiss(alert.id)}>
                                {t('dismiss')}
                              </a>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handlePinToggle(alert.id, alert.pinned)}
                              >
                                {alert.pinned ? t('unpin') : t('pin')}
                              </a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#" onClick={() => handleForward(alert.id)}>
                                {t('forward')}
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              {alert.source}
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">{alert.title}</div>
                            <div className="text-xs text-gray-600">{new Date(alert.timestamp).toLocaleString()}</div>
                            <p className="mt-2 text-gray-800">{alert.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="col-xl-6 col-lg-8">
          <h3>{t('pinned-alerts')}</h3>
          <h3>&nbsp;</h3>
          {(
            pinnedAlerts.map((alert: Alert) => (
              <div className="mb-4" key={alert.id}>
                <div className={`card border-left-warning shadow h-100 py-2`}>
                  <div className="card-body position-relative">
                    <div className="dropdown no-arrow position-absolute" style={{ top: '10px', right: '10px' }}>
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        id={`dropdownMenuLink${alert.id}`}
                        onClick={() => toggleDropdown(alert.id)}
                        aria-haspopup="true"
                        aria-expanded={openDropdown === alert.id ? 'true' : 'false'}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
                      </a>
                      {openDropdown === alert.id && (
                        <div
                          className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                          aria-labelledby={`dropdownMenuLink${alert.id}`}
                        >
                          <a className="dropdown-item" href="#" onClick={() => handleDismiss(alert.id)}>
                            {t('dismiss')}
                          </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => handlePinToggle(alert.id, alert.pinned)}
                          >
                            {alert.pinned ? t('unpin') : t('pin')}
                          </a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#" onClick={() => handleForward(alert.id)}>
                            {t('forward')}
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          {alert.source}
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{alert.title}</div>
                        <div className="text-xs text-gray-600">{new Date(alert.timestamp).toLocaleString()}</div>
                        <p className="mt-2 text-gray-800">{alert.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>

    </Loading>
  );
};

export default AlertList;
