import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ThemeSelectorComponent: React.FC = () => {
  const { t } = useTranslation();

  const [themes] = useState([
    'default',
    'dark',
  ]);

  const [selectedTheme, setSelectedTheme] = useState<string>('');

  useEffect(() => {
    // Set default values
    const defaultTheme = themes.length > 0 ? themes[0] : '';
    const storedTheme = localStorage.getItem('selectedTheme');
    setSelectedTheme(storedTheme || defaultTheme);
  }, [themes]);

  useEffect(() => {
    // Dynamically update the CSS link when the selected theme changes
    const existingLink = document.getElementById('themeStylesheet') as HTMLLinkElement | null;

    if (existingLink) {
      // If the link already exists, update its href
      existingLink.href = `${window.location.origin}/css/themes/${selectedTheme}/bootstrap.min.css`;
    } else {
      // If the link doesn't exist, create and append a new link element
      const newLink = document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.href = `${window.location.origin}/dist/css/themes/${selectedTheme}/bootstrap.min.css`;
      newLink.id = 'themeStylesheet';
      document.head.appendChild(newLink);
    }
  }, [selectedTheme]);

  const handleThemeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newTheme = event.target.value;
    setSelectedTheme(newTheme);
    localStorage.setItem('selectedTheme', newTheme);
  };

  if (themes.length > 1) {
    return (
      <div title={t('theme-selector-title', { selectedTheme })}>
        <form
          id="SelectTheme"
          onSubmit={(e) => e.preventDefault()}
          className="form-inline justify-content-end"
          role="form"
        >
          <div className="form-group">
            <label htmlFor="selectedTheme" className="mr-2">
              {t('theme-label')}
            </label>
            <select
              id="selectedTheme"
              name="theme"
              onChange={handleThemeChange}
              value={selectedTheme}
              className="form-control"
            >
              {themes.map((theme) => (
                <option key={theme} value={theme}>
                  {t(`theme-${theme}`)}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
    );
  }

  // If there's only one theme, or no themes, you might want to handle that case accordingly
  return <div></div>;
};

export default ThemeSelectorComponent;
