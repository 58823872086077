import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../store/store';
import { UserState, UserProfile } from '../types/types';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/userMockApi') : require('../api/userApi');

interface FetchUserProfileParams {
  token: string;
  userId: string;
}

export const selectCurrentUserProfile = (state: RootState) => 
  state.user.currentUserId ? state.user.profiles[state.user.currentUserId] : null;

export const fetchUserProfile = createAsyncThunk<
  { id: string; profile: UserProfile },
  FetchUserProfileParams,
  { rejectValue: string }
>(
  'user/fetchUserProfile',
  async ({ token, userId }, { rejectWithValue }) => {
    try {
      const profile: UserProfile = await api.fetchUserProfile(token, userId);
      return { id: userId, profile };
    } catch (error) {
      return rejectWithValue('Failed to fetch user profile');
    }
  }
);

export const updateUserProfile = createAsyncThunk<
  UserProfile,
  UserProfile,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'user/updateUserProfile',
  async (newProfile, { dispatch, getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    await api.updateUserProfile(accessToken, newProfile);
    return newProfile;
  }
);

const initialState: UserState = {
  profiles: {},
  currentUserId: null,
  status: 'idle',
  loading: false,
  error: null
};

const userDetailsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserId(state, action: PayloadAction<string | null>) {
      state.currentUserId = action.payload;
    },
    setStatusLoading(state) {
      state.status = 'loading';
    },
    setStatusSucceeded(state) {
      state.status = 'succeeded';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { id, profile } = action.payload;
        state.status = 'succeeded';
        state.profiles[id] = profile;
        state.currentUserId = id;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch user profile';
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action: PayloadAction<UserProfile>) => {
        if (state.currentUserId) {
          state.profiles[state.currentUserId] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update user profile';
      });
  },
});

export const { setCurrentUserId, setStatusLoading, setStatusSucceeded } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
