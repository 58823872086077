import React from 'react';

interface FormInputProps {
  label: string;
  type: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({ label, type, name, value, onChange }) => {
  return (
    <div className="form-group">
      <label>{label}:</label>
      <input type={type} className="form-control" name={name} value={value} onChange={onChange} />
    </div>
  );
};

export default FormInput;
