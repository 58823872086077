import { Alert } from '../types/alertTypes';
import { faBell, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * Simulates fetching alerts from an API.
 * @returns Promise resolving to an array of Alert objects.
 */
export const fetchAlerts = async (): Promise<Alert[]> => {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 500));

  // Mock alert data
  const mockAlerts: Alert[] = [
    {
      id: 'alert-1',
      source: 'System',
      timestamp: new Date().toISOString(),
      title: 'System Maintenance',
      description: 'Scheduled system maintenance will occur at 12:00 AM UTC.',
      pinned: false,
      icon: faInfoCircle,
    },
    {
      id: 'alert-2',
      source: 'Security',
      timestamp: new Date().toISOString(),
      title: 'Security Alert',
      description: 'Unusual login activity detected in your account.',
      pinned: true,
      icon: faExclamationTriangle,
    },
    {
      id: 'alert-3',
      source: 'Updates',
      timestamp: new Date().toISOString(),
      title: 'New Feature Released',
      description: 'Check out the new dashboard analytics feature.',
      pinned: false,
      icon: faBell,
    },
  ];

  return mockAlerts;
};

/**
 * Simulates dismissing an alert.
 * @param id - The ID of the alert to dismiss.
 * @returns Promise resolving to a success message.
 */
export const dismissAlert = async (id: string): Promise<{ message: string }> => {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 200));

  return { message: `Alert with id ${id} has been dismissed.` };
};

/**
 * Simulates pinning an alert.
 * @param id - The ID of the alert to pin.
 * @returns Promise resolving to a success message.
 */
export const pinAlert = async (id: string): Promise<{ message: string }> => {
  // Simulate API call delay
  await new Promise(resolve => setTimeout(resolve, 200));

  return { message: `Alert with id ${id} has been pinned.` };
};
