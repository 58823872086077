import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.css';

interface CarouselProps {
  items: React.ReactNode[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}

const Carousel: React.FC<CarouselProps> = ({ items, currentIndex, setCurrentIndex }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        nextItem();
      } else if (e.key === 'ArrowLeft') {
        prevItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, items?.length]);

  const nextItem = () => {
    setCurrentIndex((currentIndex + 1) % items.length);
  };

  const prevItem = () => {
    setCurrentIndex((currentIndex - 1 + items.length) % items.length);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        <div className={styles.carouselInner} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {items?.map((item, index) => (
            <div key={index} className={styles.carouselItem}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.carouselControls}>
        <button className={styles.carouselButton} onClick={prevItem}>&lt;</button>
        <button className={styles.carouselButton} onClick={nextItem}>&gt;</button>
      </div>
    </div>
  );
};

export default Carousel;
